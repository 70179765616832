import React from 'react';
// import classNames from 'classnames';
import { CardWrapper } from './index.styled';
import cx from '../../utils/cx';

export interface ICardProps {
    children: any,
    fullWidth?: boolean,
    rounded?: boolean,
    border?: boolean,
    borderPrimary?: boolean,
    shadow?: boolean,
    className?: string,
    style?: React.CSSProperties
}

const Card: React.FC<ICardProps> = (props) => {
    const { children, fullWidth, rounded, border, borderPrimary, shadow, className, style } = props;
    return (
        <CardWrapper shadow={shadow} className={cx({ className, fullWidth, rounded, border, borderPrimary })} style={style}>
            {children}
        </CardWrapper>
    );
};

Card.defaultProps = {
    fullWidth: false,
    border: false,
    rounded: false,
    borderPrimary: false,
    shadow: false,
    className: '',
    style: undefined
};

export default Card;
