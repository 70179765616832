import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

interface IScroolView {
    children: any
    // shadowOnScroll?: boolean
}

const ScrollView: React.FC<IScroolView> = (props) => {
    const { children } = props;

    return (
        <Scrollbars>
            {children}
        </Scrollbars>
    );
};

// ScrollView.defaultProps = {
//     shadowOnScroll: false
// };

export default ScrollView;
