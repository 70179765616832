import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Badge from '../../../components/Badge';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Table from '../../../components/Table';
import useDate from '../../../helpers/useDate';
import useProduct from '../../../hooks/useProduct';
import ImportDataDialog from '../components/ImportDataDialog';

const ProductList: React.FC = () => {
    const { status } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const { list, totalList, loading } = useProduct({ currentPage, limit: 10, status });

    const [totalRow, setTotalRow] = useState(0);
    const [importDialog, setImportDialog] = useState(false);

    useEffect(() => {
        if (totalList > 0) {
            setTotalRow(totalList);
        }
    }, [list, loading]);

    const columns: any[] = [
        {
            name: 'Tanggal',
            sortable: true,
            selector: (row: any) => useDate.friendlyDate(row.created_at)
        },
        {
            name: 'Name',
            sortable: true,
            allowOverflow: true,
            selector: (row: any) => row.name
        },
        {
            name: 'Type / Category',
            sortable: true,
            minWidth: '80px',
            selector: (row: any) => row.category_name
        },
        {
            name: 'Model',
            sortable: true,
            selector: (row: any) => row.model
        },
        {
            name: 'Status',
            sortable: true,
            selector: (row: any) => (
                <>
                    {row.status === 'INACTIVE' && (
                        <Badge status="error">{row.status}</Badge>
                    )}
                    {row.status === 'ACTIVE' && (
                        <Badge status="success">{row.status}</Badge>
                    )}
                </>
            )
        },
        {
            allowOverflow: true,
            cell: (row: any) => <Button borderPrimary link to={`/admin/product/edit/${row.id}`} className="btn-small">EDIT</Button>,
            style: {
                justifyContent: 'flex-end'
            }
        }
    ];

    return (
        <>
            <div className="row my-4">
                <div className="col-6" />
                <div className="col-2" />
                <div className="col-2 text-end">
                    <Button block fullWidth borderPrimary onClick={() => setImportDialog(true)}>Import</Button>
                </div>
                <div className="col-2 text-end">
                    <Button block fullWidth primary link to="/admin/product/add">+ Add</Button>
                </div>
            </div>
            <Card border>
                <Table
                    data={list}
                    columns={columns}
                    loading={loading}
                    paginate
                    currentPage={currentPage}
                    totalList={totalRow}
                    onPageChange={(e) => {
                        setCurrentPage(e.selected + 1);
                    }}
                />
            </Card>
            {importDialog && (
                <ImportDataDialog dialogHandler={(visible: boolean) => setImportDialog(visible)} />
            )}
        </>
    );
};

export default ProductList;
