import { useState, useEffect } from 'react';
import API from '../configs/api';
import { hideLoading, showLoading } from '../redux/action';
import { useAppDispatch } from './useRedux';

interface IRewardQuery {
    currentPage?: number,
    limit?: number
}

const useReward = (query: IRewardQuery) => {
    const dispatch = useAppDispatch();
    const { currentPage, limit }: any = query;
    const [list, setList] = useState<any>([]);
    const [totalList, setTotalList] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        dispatch(showLoading());
        setLoading(true);

        const payload = {
            query: { currentPage: currentPage || 1, limit: limit || 10 }
        };

        API.getReward(payload).then((result: any) => {
            if (result.data?.total) {
                setTotalList(result.data.total);
            }
            setList(result.data.list);
            setLoading(false);
            dispatch(hideLoading());
        }).catch(() => {
            setList([]);
            setTotalList(0);
            setLoading(false);
            dispatch(hideLoading());
        });
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    return { list, totalList, loading } as const;
};

export default useReward;
