import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Table from '../../components/Table';
import Text from '../../components/Text';
import useCurrency from '../../helpers/useCurrency';
import { useAppSelector } from '../../hooks/useRedux';
import useReward from '../../hooks/useReward';
import Admin from '../../layouts/Admin';
import AddRewardDialog from './components/RewardDialog';

const Reward: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { profile } = useAppSelector((state) => state.authReducer);
    const { list, totalList, loading } = useReward({ currentPage: 1, limit: 10 });
    const [rewardDialog, setRewardDialog] = useState(false);
    const [editData, setEditData] = useState<any>({});

    const [totalRow, setTotalRow] = useState(0);

    useEffect(() => {
        if (totalList > 0) {
            setTotalRow(totalList);
        }
    }, [list]);

    const columns: any[] = [
        {
            name: 'ID',
            sortable: true,
            width: '80px',
            selector: (row: any) => row.id
        },
        {
            name: 'Type',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => <Text style={{ color: 'var(--default-primary)' }} fontSmall>{row.reward_type}</Text>
        },
        {
            name: 'Amount',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => `Rp. ${useCurrency(row.amount)}`
        },
        {
            name: 'Code',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.reward_code
        },
        {
            name: 'Description',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.description
        },
        {
            allowOverflow: true,
            cell: (row: any) => (
                <Button
                    borderPrimary
                    className="btn-small"
                    onClick={() => {
                        setRewardDialog(true);
                        setEditData(row);
                    }}
                >
                    Edit
                </Button>
            ),
            style: {
                justifyContent: 'flex-end'
            }
        }
    ];

    return (
        <Admin activeMenu="reward">
            {profile?.role_id === 1 && (
                <div className="row mb-3">
                    <div className="col-6" />
                    <div className="col-6 text-end">
                        <Button primary onClick={() => setRewardDialog(true)}>+ Add</Button>
                    </div>
                </div>
            )}
            <Card border>
                <Table
                    data={list}
                    columns={columns}
                    loading={loading}
                    paginate
                    currentPage={currentPage}
                    totalList={totalRow}
                    onPageChange={(e) => {
                        setCurrentPage(e.selected + 1);
                    }}
                />
            </Card>
            {rewardDialog && (
                <AddRewardDialog
                    edit={!!editData.id}
                    data={editData}
                    dialogHandler={(visible: boolean) => {
                        setRewardDialog(visible);
                        if (visible === false) {
                            setEditData({});
                        }
                    }}
                />
            )}
        </Admin>
    );
};

export default Reward;
