import { useState, useEffect } from 'react';
import API from '../configs/api';

interface IProductQuery {
    currentPage?: number,
    limit?: number,
    category_id?: number,
    product_id?: number,
    status?: string
}

const useProduct = (query: IProductQuery) => {
    const { currentPage, limit, category_id, product_id, status }: any = query;

    const [list, setList] = useState<any>([]);
    const [totalList, setTotalList] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);

        const payload = {
            params: product_id ? `/${product_id}` : '',
            query: { currentPage: currentPage || 1, limit: limit || 10, category_id: category_id || '', status: status || '' }
        };
        API.getProduct(payload).then((result: any) => {
            if (result.data.total) {
                setTotalList(result.data.total);
            }
            setList(result.data.list);
            setLoading(false);
        }).catch(() => {
            setList([]);
            setTotalList(0);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchData();

        return () => {
            setList(list);
            setTotalList(totalList);
            setLoading(false);
        };
    }, [currentPage, limit, category_id, product_id, status]);

    return { list, totalList, loading } as const;
};

export default useProduct;
