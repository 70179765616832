import styled from 'styled-components';

const InputTextLabel = styled.label<{bold?: boolean, extraBold?: boolean}>`
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
    cursor: pointer;
`;

const InputTextWrapper = styled.input`
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
    min-height: 42px;
    padding: 6px 1rem;
    border-radius: 10px;
    background: var(--default-bg);
    color: var(--default-fg);
    border: none;
    outline: none;
`;

const InputIconWrapper = styled.div`
    position: absolute;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &#left {
        left: 1rem;
    }

    &#right {
        right: 1rem;
    }
`;

const InputTextGroup = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;

    +${InputTextLabel} {
        margin-top: 10px;
    }

    &:has(#left) {
        ${InputTextWrapper} {
            padding-left: 3rem;
        }
    }

    &:has(#right) {
        ${InputTextWrapper} {
            padding-right: 3rem;
        }
    }
`;

export {
    InputTextGroup,
    InputTextWrapper,
    InputTextLabel,
    InputIconWrapper
};
