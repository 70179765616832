import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Card from '../../components/Card';
// import CheckBox from '../../components/CheckBox';
import Image from '../../components/Image';
import InputText from '../../components/InputText';
import Modal from '../../components/Modal';
import SelectInput from '../../components/SelectInput';
import Text from '../../components/Text';
import TextArea from '../../components/TextArea';
import API from '../../configs/api';
import useDate from '../../helpers/useDate';
import { useAppDispatch } from '../../hooks/useRedux';
import useReward from '../../hooks/useReward';
import Admin from '../../layouts/Admin';
import { hideLoading, showLoading, showToast } from '../../redux/action';
import './styles.scss';

const TransactionDetail: React.FC = () => {
    const { trx_id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const reward = useReward({ currentPage: 1, limit: 1000 });
    const [data, setData] = useState<any>({});
    const [rewardOpt, setRewardOpt] = useState<any[]>([]);
    const [rejectDialog, setRejectDialog] = useState(false);
    const [reason, setReason] = useState('');
    const [transactions, setTransactions] = useState<any[]>([]);

    useEffect(() => {
        if (!data.transaction_details) return;
        const transactionArr: any[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data.transaction_details.length; i++) {
            const el = data.transaction_details[i];
            transactionArr.push({
                id: el.id,
                reward_id: el?.reward_id || 0,
                reward_type: el?.reward_type || 'REJECT',
                reward_code: el?.reward_code || '',
                reward_amount: el?.reward_amount || 0,
                reward_description: el?.reward_description || '',
                status: el?.status || 2
            });
        }
        setTransactions(transactionArr);
    }, [data]);

    const fetchData = () => {
        dispatch(showLoading());
        const payload = {
            params: `/${trx_id}`
        };

        API.getTransaction(payload).then((result: any) => {
            setData(result.data);
            dispatch(hideLoading());
        }).catch(() => {
            dispatch(hideLoading());
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const approveTransaction = () => {
        dispatch(showLoading());
        const payload = {
            params: `/${trx_id}`,
            body: {
                transactions
            }
        };
        API.approveTransaction(payload).then((result: any) => {
            dispatch(showToast(result.meta.message, 'success'));
            setTimeout(() => {
                dispatch(hideLoading());
                navigate(0);
            }, 1000);
        }).catch(() => {
            dispatch(hideLoading());
        });
    };

    const rejectTransaction = () => {
        dispatch(showLoading());
        const payload = {
            params: `/${trx_id}`,
            body: {
                reason
            }
        };
        API.rejectTransaction(payload).then((result: any) => {
            dispatch(showToast(result.meta.message, 'success'));
            setTimeout(() => {
                dispatch(hideLoading());
                navigate(0);
            }, 1000);
        }).catch(() => {
            dispatch(hideLoading());
        });
    };

    useEffect(() => {
        if (reward.list < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < reward.list.length; i++) {
            const obj = {
                title: reward.list[i]?.description,
                reward_type: reward.list[i]?.reward_type,
                reward_code: reward.list[i]?.reward_code,
                reward_amount: reward.list[i]?.amount,
                value: reward.list[i]?.id
            };
            setRewardOpt((e: any) => [...e, obj]);
        }
    }, [reward.list]);

    const handleChangeReward = (index: number, value: any) => {
        const trx = [...transactions];
        trx[index].reward_id = value.value;
        trx[index].reward_type = value.reward_type;
        trx[index].reward_code = value.reward_code;
        trx[index].reward_amount = value.reward_amount;
        trx[index].reward_description = value.title;
        trx[index].status = 1;
        setTransactions(trx);
    };
    function downloadImage(url: string) {
        const a = document.createElement('a');
        a.href = url;
        a.download = url;
        a.target = '_blank';
        a.click();
        // fetch(url, {
        //     mode: 'no-cors'
        // })
        //     .then((response) => response.blob())
        //     .then((blob) => {
        //         const blobUrl = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         // eslint-disable-next-line no-useless-escape
        //         a.download = url.replace(/^.*[\\\/]/, '');
        //         a.href = blobUrl;
        //         document.body.appendChild(a);
        //         a.click();
        //         a.remove();
        //     });
    }
    return (
        <Admin>
            <Card border>
                <div className="row">
                    <div className="col-6 mb-3">
                        <Text paragraph fontBold>ID Transaksi</Text>
                        <Text paragraph>{`#${data.trx_id}`}</Text>
                        <Badge gray className="me-2">{data.category_name}</Badge>
                        {data?.status === 'PENDING' && (
                            <Badge status="warning">{data.status}</Badge>
                        )}
                        {data?.status === 'APPROVED' && (
                            <Badge status="success">{data.status}</Badge>
                        )}
                        {data?.status === 'REJECT' && (
                            <Badge status="error">{data.status}</Badge>
                        )}
                    </div>
                    <div className="col-6 text-end mb-3">
                        <Text paragraph fontBold className="mt-2">Tanggal</Text>
                        {`${useDate.friendlyDate(data.created_at)}`}
                    </div>
                    {data?.reject_reason && (
                        <div className="col-12 mb-4">
                            <div className="reason-wrapper">
                                <Text paragraph fontSmall>Alasan penolakan :</Text>
                                <Text paragraph fontSmall className="reason">{data.reject_reason}</Text>
                            </div>
                        </div>
                    )}
                    <div className="col-12 mb-4">
                        <Text h6 fontBold className="mb-3">Data Customer</Text>
                        <hr />
                        <div className="row">
                            <div className="col-4">
                                <Text paragraph fontBold>Nama</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.customer_name}</Text>
                                </div>
                            </div>
                            <div className="col-4">
                                <Text paragraph fontBold>No. HP</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.customer_phone}</Text>
                                </div>
                            </div>
                            <div className="col-4">
                                <Text paragraph fontBold>Tgl Pembelian</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{`${moment(data?.date).format('YYYY-MM-DD')}`}</Text>
                                </div>
                            </div>
                            {/* <div className="col-6">
                                <Text paragraph fontBold>Jenis Kelamin</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.gender || '-'}</Text>
                                </div>
                            </div>
                            <div className="col-6">
                                <Text paragraph fontBold>Nomor KTP (NIK)</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.identity_number || '-'}</Text>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <Text h6 fontBold className="mb-3">Data Product</Text>
                        <hr />
                        <div className="row">
                            <div className="col-6">
                                <Text paragraph fontBold>Serial Number / IMEI</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.serial_number}</Text>
                                </div>
                            </div>
                            <div className="col-6">
                                <Text paragraph fontBold>Model</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.model}</Text>
                                </div>
                            </div>
                            <div className="col-6">
                                <Text paragraph fontBold>Toko Pembelian</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.store || '-'}</Text>
                                </div>
                            </div>
                            <div className="col-6">
                                <Text paragraph fontBold>Cabang / Kota</Text>
                                <div className="detail-input-data">
                                    <Text paragraph>{data?.branch_name || data?.city || '-'}</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Text h6 fontBold className="mb-3">Foto</Text>
                        <hr />
                        <div className="row">
                            <div className="col-4">
                                <Text paragraph fontBold className="mb-3">Invoice</Text>
                                <div className="uploaded-img mb-3">
                                    {data?.receipt_img && (
                                        <Image zoom src={data?.receipt_img} />
                                    )}
                                </div>
                                <Button className="d-none" borderPrimary onClick={() => downloadImage(data.receipt_img)}>Download</Button>
                            </div>
                            <div className="col-4">
                                <Text paragraph fontBold className="mb-3">Kartu Garansi</Text>
                                {data?.guarantee_img ? (
                                    <div className="uploaded-img">
                                        <Image zoom src={data?.guarantee_img} />
                                    </div>
                                ) : (
                                    <Text>Tidak Ada</Text>
                                )}
                            </div>
                            <div className="col-4">
                                <Text paragraph fontBold className="mb-3">Screenshoot</Text>
                                <div className="uploaded-img">
                                    {data?.serial_number_img && (
                                        <Image zoom src={data?.serial_number_img} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Text h6 fontBold className="my-3">Promo</Text>
                        <hr />
                        {data.status === 'PENDING' && (
                            <div id="highlight-wrapper">
                                <Text paragraph>Silahkan pilih reward untuk promo yang disetujui.</Text>
                            </div>
                        )}
                        {data?.transaction_details && data?.transaction_details.map((i: any, idx: any) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className="row mb-4" key={idx}>
                                <div className="col-4">
                                    <Text paragraph fontBold>Nama Promo</Text>
                                    <Text paragraph>{i.campaign_name}</Text>
                                </div>
                                <div className="col-4">
                                    {data.status === 'PENDING' && (
                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                        <>
                                            {rewardOpt.length > 0 ? (
                                                <SelectInput
                                                    label="Reward"
                                                    options={rewardOpt}
                                                    placeholder="Pilih reward"
                                                    value={transactions[idx]?.reward_description}
                                                    onOptionSelected={(value: any) => {
                                                        handleChangeReward(idx, value);
                                                    }}
                                                />
                                            ) : (
                                                <InputText label="Reward" border block />
                                            )}
                                        </>
                                    )}
                                    {data.status === 'APPROVED' && i.reward_id !== 0 && (
                                        <>
                                            <Text paragraph fontBold>Reward</Text>
                                            <div className="detail-input-data">
                                                <Text paragraph>{i?.reward_description}</Text>
                                            </div>
                                        </>
                                    )}
                                    {data.status === 'APPROVED' && i.reward_id === 0 && (
                                        <>
                                            <Text paragraph fontBold>Reward</Text>
                                            <div className="detail-input-data">
                                                <Text paragraph style={{ color: 'red' }}>REJECT</Text>
                                            </div>
                                        </>
                                    )}
                                    {data.status === 'REJECT' && (
                                        <>
                                            <Text paragraph fontBold>Reward</Text>
                                            <div className="detail-input-data">
                                                <Text paragraph>REJECT</Text>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="col-4" />
                                {/* <div className="col-4">
                                    <Text paragraph fontBold>Aksi</Text>
                                    <div className="row">
                                        <div className="col-6">
                                            <Button borderPrimary block fullWidth rounded>Tolak</Button>
                                        </div>
                                        <div className="col-6">
                                            <Button borderPrimary block fullWidth rounded>Setuju</Button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
            {data.status === 'PENDING' && (
                <div className="row mt-3">
                    <div className="col-6" />
                    <div className="col-3">
                        <Button block fullWidth borderPrimary onClick={() => setRejectDialog(true)}>Reject</Button>
                    </div>
                    <div className="col-3">
                        <Button block fullWidth primary onClick={() => approveTransaction()}>Approve</Button>
                    </div>
                </div>
            )}
            <Modal title="Reject Transaction" visibility={rejectDialog}>
                <TextArea rows={6} border block onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)} value={reason} />
                <div className="row mt-3">
                    <div className="col-6">
                        <Button
                            fullWidth
                            block
                            borderPrimary
                            onClick={() => {
                                setRejectDialog(false);
                                setReason('');
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button
                            fullWidth
                            block
                            primary
                            disabled={reason.length < 1}
                            onClick={() => {
                                rejectTransaction();
                                setRejectDialog(false);
                            }}
                        >
                            Reject
                        </Button>
                    </div>
                </div>
            </Modal>
        </Admin>
    );
};

export default TransactionDetail;
