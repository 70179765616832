import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducers/auth';
import uiReducer from '../reducers/ui';

const store = configureStore({
    reducer: {
        authReducer,
        uiReducer
    },
    devTools: true
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
