const menuList: any[] = [
    // {
    //     id: 0,
    //     title: 'DASHBOARD',
    //     menu: [
    //         {
    //             id: 'dashboard',
    //             link: '/',
    //             title: 'Dashboard',
    //             icon: 'chart'
    //         }
    //     ]
    // },
    // {
    //     id: 1,
    //     title: 'DATA MASTER',
    //     menu: [
    //         {
    //             id: 'category',
    //             link: '/category',
    //             title: 'Category',
    //             icon: 'folder'
    //         },
    //         {
    //             id: 'branch',
    //             link: '/branch',
    //             title: 'Branch',
    //             icon: 'folder'
    //         },
    //         {
    //             id: 'product',
    //             link: '/product',
    //             title: 'Product',
    //             icon: 'paper'
    //         },
    //         {
    //             id: 'qr-code',
    //             link: '/qr-code',
    //             title: 'QR-Code',
    //             icon: 'qr'
    //         }
    //     ]
    // },
    // {
    //     id: 2,
    //     title: 'PROMO',
    //     menu: [
    //         {
    //             id: 'campaign',
    //             link: '/campaign',
    //             title: 'Campaign',
    //             icon: 'discount'
    //         },
    //         {
    //             id: 'reward',
    //             link: '/reward',
    //             title: 'Reward',
    //             icon: 'ticket'
    //         }
    //     ]
    // },
    {
        id: 3,
        title: 'TRANSACTION',
        menu: [
            {
                id: 'transaction',
                link: '/transaction',
                title: 'Transaction',
                icon: 'swap'
            },
            {
                id: 'mutation',
                link: '/mutation',
                title: 'Mutation',
                icon: 'swap'
            }
        ]
    }
    // {
    //     id: 4,
    //     title: 'USER MANAGEMENT',
    //     menu: [
    //         {
    //             id: 'user',
    //             link: '/user',
    //             title: 'User',
    //             icon: 'multiuser'
    //         }
    //     ]
    // }
    // {
    //     id: 5,
    //     title: 'REPORTING',
    //     menu: [
    //         {
    //             id: 'reward-report',
    //             link: '/report/reward',
    //             title: 'Reward',
    //             icon: 'graph'
    //         },
    //         {
    //             id: 'reward-reportw',
    //             link: '/report/reward',
    //             title: 'Reward3',
    //             icon: 'graph'
    //         },
    //         {
    //             id: 'reward-report2',
    //             link: '/report/reward',
    //             title: 'Reward',
    //             icon: 'graph'
    //         },
    //         {
    //             id: 'reward-report1',
    //             link: '/report/reward',
    //             title: 'Reward',
    //             icon: 'graph'
    //         }
    //     ]
    // }
];

export default menuList;
