import styled from 'styled-components';

const CheckBoxWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 36px;
    padding: 6px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
`;

const CheckBoxLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: left;
    margin-left: 5px;
`;

const CheckBoxInner = styled.div`
    position: relative;
    display: inline-flex;
    width: 42px;
    height: 42px;
    padding: 10px;
    color: var(--default-primary);
    border-radius: 100px;
    background: transparent;
    cursor: pointer;

    &:hover {
        background: var(--default-fg-hover);
    }
    
    input {
        position: absolute;
        left: 0;
        opacity: 0;
        display: none;
    }

    svg {
        fill: var(--default-primary);
    }

    // &:has(input:checked) {
    //     +sc
    // }
`;

export {
    CheckBoxWrapper,
    CheckBoxLabel,
    CheckBoxInner
};
