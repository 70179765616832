const CONSTANT = {
    APP: {
        SESSION_KEY: 'tkb-session'
    },
    API: {
        BASE_URL: process.env.REACT_APP_API_URL || ''
        // BASE_URL: 'http://localhost:6009/api/v1'
    }
};

export default CONSTANT;
