import React, { useState } from 'react';
import Card from '../../../../components/Card';
import Image from '../../../../components/Image';
import InputText from '../../../../components/InputText';
import Main from '../../../../layouts/Main';
import logo from '../../../../assets/img/logo-sharp-red.png';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';

const VoucherAuth: React.FC = () => {
    const [phone, setPhone] = useState('+62 ');

    const handleInputChange = (value: string) => {
        const regex = /^[0-9 +]{4,14}$/;
        if (regex.test(value)) {
            setPhone(value);
        }
    };

    return (
        <Main>
            <Card shadow>
                <div className="logo">
                    <Image src={logo} />
                </div>
                <Text h5 fontBold className="text-center mb-2">LOGIN</Text>
                <Text paragraph className="text-center mb-4">Silahkan Masukkan Nomor HP Anda</Text>
                <Text paragraph fontBold fontSmall>Nomor HP</Text>
                <InputText
                    className="mb-3"
                    border
                    type="text"
                    placeholder="6281511145014"
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
                />
                <Button block fullWidth primary className="animate-rounded">Login</Button>
            </Card>
        </Main>
    );
};

export default VoucherAuth;
