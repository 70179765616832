import React from 'react';
import cx from '../../utils/cx';
import { BadgeWrapper } from './index.styled';

type statusType = 'success' | 'warning' | 'error' | undefined;

interface IBadgeProps {
    children: any,
    status?: statusType,
    rounded?: boolean,
    gray?: boolean,
    className?: string
}

const Badge: React.FC<IBadgeProps> = (props) => {
    const { children, status, rounded, gray, className } = props;
    return (
        <BadgeWrapper className={(cx({ className, rounded, gray, status }))}>
            {children}
        </BadgeWrapper>
    );
};

Badge.defaultProps = {
    status: undefined,
    className: undefined,
    rounded: false,
    gray: false
};

export default Badge;
