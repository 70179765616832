import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CONSTANT from '../../../constant';

const initToken = localStorage.getItem(CONSTANT.APP.SESSION_KEY) || '';
const initProfile = localStorage.getItem('user-data') || '{}';

interface IState {
    loggedIn: boolean,
    token: string,
    profile: any
}

const initialState: IState = {
    loggedIn: !!initToken,
    token: initToken || '',
    profile: JSON.parse(initProfile) || {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setProfile: (state, action: PayloadAction<any>) => {
            state.loggedIn = true;
            state.profile = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        }
    }
});

export const { setProfile, setToken } = authSlice.actions;

export default authSlice.reducer;
