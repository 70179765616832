import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import Modal from '../../../components/Modal';
import API from '../../../configs/api';
import { useAppDispatch } from '../../../hooks/useRedux';
import { hideLoading, showLoading, showToast } from '../../../redux/action';

interface IAddBranchDialog {
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const AddBranchDialog: React.FC<IAddBranchDialog> = (props) => {
    const { dialogHandler } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState('');

    const handleSubmit = () => {
        dispatch(showLoading());
        const payload = {
            body: { name }
        };
        API.createBranch(payload).then((result: any) => {
            dialogHandler(false);
            setTimeout(() => {
                dispatch(showToast(result.meta.message, 'success'));
                dispatch(hideLoading());
                navigate(0);
            }, 1000);
        });
    };

    return (
        <Modal
            title="Add Branch"
            visibility
            modalHandler={dialogHandler}
        >
            <InputText label="Name" block border type="text" value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
            <div id="modal-footer" className="row mt-3">
                <div className="col-6">
                    <Button block fullWidth borderPrimary onClick={() => dialogHandler(false)}>Cancel</Button>
                </div>
                <div className="col-6">
                    <Button block fullWidth primary onClick={() => handleSubmit()}>Add</Button>
                </div>
            </div>
        </Modal>
    );
};

export default AddBranchDialog;
