import { utils, writeFile } from 'xlsx-js-style';
import useDate from '../../../helpers/useDate';

const transactionExport = async (data: any[]) => {
    const header = [
        '#',
        'Created At',
        'ID Transaksi',
        'SN / IMEI',
        'Nama Customer',
        'No HP Customer',
        'No Ewallet',
        'Jenis Kelamin',
        'No KTP (NIK)',
        'Reward Code',
        'Reward Type',
        'Reward Amount',
        'Reward Description',
        'Kategori',
        'Model',
        'Store',
        'Branch',
        'Status',
        'Status Terkirim'
    ];

    const rows: any[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        rows.push({
            A: i + 1,
            B: useDate.getDate(item.created_at),
            C: item.trx_id,
            D: item.serial_number,
            E: item.customer_name,
            F: item.customer_phone,
            G: item.ewallet_number,
            H: item.gender,
            I: item.identity_number,
            J: item.reward_code,
            K: item.reward_type,
            L: item.reward_amount,
            M: item.reward_description,
            N: item.category_name,
            O: item.model,
            P: item.store || '-',
            Q: item.branch || '-',
            R: item.status,
            S: item.is_sent === 1 ? 'TERKIRIM' : ''
        });
    }

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(rows);

    utils.book_append_sheet(wb, ws, 'TRANSAKSI');
    utils.sheet_add_aoa(ws, [header], { origin: 'A1' });
    writeFile(wb, 'Transaction.xlsx');
};

export default transactionExport;
