import React from 'react';
import { useNavigate } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';

interface IImageProps {
    src: any,
    alt?: string,
    link?: boolean,
    linkReplace?: boolean,
    to?: string,
    onClick?: any
    className?: string,
    crossOrigin?: any,
    zoom?: boolean
}

const Image: React.FC<IImageProps> = (props) => {
    const { src, alt, link, linkReplace, to, onClick, className, crossOrigin, zoom } = props;
    const navigate = useNavigate();

    const onClickLink = () => {
        navigate(to || '', { replace: linkReplace });
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Suspense fallback="Loading ...">
            {zoom ? (
                <Zoom>
                    <img crossOrigin={crossOrigin} className={className} src={src} alt={alt} onClick={link ? onClickLink : onClick} />
                </Zoom>
            ) : (
                <img crossOrigin={crossOrigin} className={className} src={src} alt={alt} onClick={link ? onClickLink : onClick} />
            )}
        </React.Suspense>
    );
};

Image.defaultProps = {
    alt: 'teknologi-kawasan-bahagia',
    link: false,
    linkReplace: false,
    to: '/',
    onClick: () => { },
    className: '',
    crossOrigin: undefined,
    zoom: false
};

export default Image;
