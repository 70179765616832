import React from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../../components/Loading';
import Toast from '../../components/Toast';
import { useAppSelector } from '../../hooks/useRedux';
import { AuthBanner, AuthContent, AuthWrapper } from './index.styled';

interface IAuthProps {
    children: any
}

const Auth: React.FC<IAuthProps> = (props) => {
    const { children } = props;
    const { theme, sidebar, loading, toastMessage, toastStatus, toastVisible } = useAppSelector((state) => state.uiReducer);

    return (
        <>
            <Helmet>
                <html lang="id" data-theme-mode={theme} />
            </Helmet>
            <Toast position="top-right" visibility={toastVisible} status={toastStatus}>{toastMessage}</Toast>
            <AuthWrapper className={sidebar ? '' : 'sidebar-minimize'}>
                <AuthContent>
                    {children}
                </AuthContent>
                <AuthBanner />
            </AuthWrapper>
            <Loading visibility={loading} />
        </>
    );
};

export default Auth;
