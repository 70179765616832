import React from 'react';
import Card from '../../../../components/Card';
import Image from '../../../../components/Image';
import Main from '../../../../layouts/Main';
import logo from '../../../../assets/img/logo-sharp-red.png';
import Button from '../../../../components/Button';

const VoucherProgram: React.FC = () => {
    return (
        <Main>
            <div className="logo">
                <Image src={logo} />
            </div>
            <Card shadow>
                <Button block fullWidth primary className="animate-rounded mb-3">JAPAN TRIP</Button>
                <Button block fullWidth primary className="animate-rounded mb-3">PROMO GARANSI ZFIX</Button>
                <Button block fullWidth primary className="animate-rounded mb-3">PROGRAM GOPAY SMARTPHONE</Button>
                <Button block fullWidth primary className="animate-rounded">PROGRAM GOPAY DYNABOOK</Button>
            </Card>
        </Main>
    );
};

export default VoucherProgram;
