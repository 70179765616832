import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import Admin from '../../layouts/Admin';

const tabs = [
    { id: 0, title: 'PENDING', key: 'pending', link: '/admin/transaction' },
    { id: 1, title: 'APPROVED', key: 'approved', link: '/admin/transaction/approved' },
    { id: 2, title: 'REJECT', key: 'reject', link: '/admin/transaction/reject' },
    { id: 3, title: 'ALL', key: 'all', link: '/admin/transaction/all' },
    { id: 4, title: 'STATUS', key: 'status', link: '/admin/transaction/status' }
];

const Transaction: React.FC = () => {
    const { status } = useParams();

    return (
        <Admin activeMenu="transaction" hasTab>
            <Tabs tabItems={tabs} link defaultTabActive={status || 'pending'} />
            <Outlet />
        </Admin>
    );
};

export default Transaction;
