import styled from 'styled-components';

const TabItemWrapper = styled.div<{active?: boolean}>`
    position: relative;
    display: block;
    padding: 10px 15px;
    margin: 0 1px;
    color: ${(props) => (props.active ? 'var(--default-primary)' : 'var(--default-fg)')};
    border-bottom:  ${(props) => (props.active ? '2px solid var(--default-primary)' : 'none')};
    cursor: pointer;
`;

const TabWrapper = styled.div<{fullWidth?: boolean, floating?: boolean}>`   
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.fullWidth ? 'space-between' : 'flex-start')};
    border-bottom: 1px solid var(--default-border);
    background: var(--default-canvas);
    align-items: center;
    top: 56px;
    z-index: 9;
    ${TabItemWrapper} {
        width:  ${(props) => (props.fullWidth ? '100%' : 'auto')};
        text-align: center;
    }
`;

export {
    TabWrapper,
    TabItemWrapper
};
