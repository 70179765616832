import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import Modal from '../../../components/Modal';
import SelectInput from '../../../components/SelectInput';
import API from '../../../configs/api';
import transactionExport from '../../../configs/report/transaction';
import useDate from '../../../helpers/useDate';
import useCategory from '../../../hooks/useCategory';
import useProduct from '../../../hooks/useProduct';

interface IExportDialog {
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const ExportDialog: React.FC<IExportDialog> = (props) => {
    const { dialogHandler } = props;
    const category = useCategory(1, 10000);

    const [categoryOpt, setCategoryOpt] = useState<any[]>([]);
    const [categorySelected, setCategorySelected] = useState<any>({});

    const product = useProduct({ category_id: categorySelected.value || '', currentPage: 1, limit: 1000 });

    const [modelOpt, setModelOpt] = useState<any[]>([]);
    const [modelSelected, setModelSelected] = useState<any>({});

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const onExport = () => {
        const payload = {
            query: {
                download: true,
                category: categorySelected.value || '',
                product: modelSelected.value || '',
                start_date: useDate.getDate(startDate),
                end_date: useDate.getDate(endDate)
            }
        };
        API.getTransaction(payload).then((result: any) => {
            transactionExport(result.data.list);
        });
    };

    useEffect(() => {
        if (category.list < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < category.list.length; i++) {
            const obj = {
                title: category.list[i]?.name,
                value: category.list[i]?.id
            };
            setCategoryOpt((e: any) => [...e, obj]);
        }
    }, [category.list]);

    useEffect(() => {
        if (product.list < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < product.list.length; i++) {
            const obj = {
                title: product.list[i]?.model,
                value: product.list[i]?.id
            };
            setModelOpt((e: any) => [...e, obj]);
        }
    }, [product.list]);

    return (
        <Modal title="Export Transaction" visibility modalHandler={dialogHandler}>
            {categoryOpt.length > 0 ? (
                <SelectInput
                    label="Category"
                    value={categorySelected.title}
                    options={categoryOpt}
                    placeholder="Category"
                    onOptionSelected={(value: any) => {
                        setCategorySelected(value);
                    }}
                />
            ) : (
                <InputText label="Category" />
            )}
            {modelOpt.length > 0 ? (
                <SelectInput
                    label="Model"
                    value={modelSelected.title}
                    options={modelOpt}
                    placeholder="Model"
                    onOptionSelected={(value: any) => {
                        setModelSelected(value);
                    }}
                />
            ) : (
                <InputText label="Category" />
            )}
            <div className="row mt-2">
                <div className="col-6">
                    <InputText
                        label="Start Date"
                        block
                        border
                        type="date"
                        value={startDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-6">
                    <InputText
                        label="End Date"
                        block
                        border
                        type="date"
                        value={endDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    <Button block borderPrimary fullWidth onClick={() => dialogHandler(false)}>Cancel</Button>
                </div>
                <div className="col-6">
                    <Button block disabled={!startDate || !endDate || !categorySelected.value} primary fullWidth onClick={() => onExport()}>Export</Button>
                </div>
            </div>
        </Modal>
    );
};

// ExportDialog.defaultProps = {
//     dialogHandler: ()
// };

export default ExportDialog;
