import React, { useEffect } from 'react';
import Admin from '../../layouts/Admin';

const Dashboard: React.FC = () => {
    useEffect(() => {
        window.location.replace('/admin/transaction');
    }, []);

    return (
        <Admin activeMenu="dashboard">
            ok
        </Admin>
    );
};

export default Dashboard;
