import CONSTANT from '../../constant';
import ApiConfig from './config';

const { BASE_URL } = CONSTANT.API;

const API = {
    loginAdmin: ApiConfig.POST(`${BASE_URL}/auth/login`, false),
    getProduct: ApiConfig.GET(`${BASE_URL}/product`, true),
    createProduct: ApiConfig.POST(`${BASE_URL}/product`, true),
    updateProduct: ApiConfig.PUT(`${BASE_URL}/product`, true),
    importProduct: ApiConfig.POST(`${BASE_URL}/product/import`, true),
    getBranch: ApiConfig.GET(`${BASE_URL}/branch`, true),
    createBranch: ApiConfig.POST(`${BASE_URL}/branch`, true),
    getCategory: ApiConfig.GET(`${BASE_URL}/category`, true),
    createCategory: ApiConfig.POST(`${BASE_URL}/category`, true),
    getCampaign: ApiConfig.GET(`${BASE_URL}/campaign`, true),
    createCampaign: ApiConfig.POST(`${BASE_URL}/campaign`, true),
    getTransaction: ApiConfig.GET(`${BASE_URL}/transaction`, true),
    getTransactionStatus: ApiConfig.GET(`${BASE_URL}/transaction/status/gopay`, true),
    createTransaction: ApiConfig.POST(`${BASE_URL}/transaction`, false),
    approveTransaction: ApiConfig.PUT(`${BASE_URL}/transaction/approve`, true),
    rejectTransaction: ApiConfig.PUT(`${BASE_URL}/transaction/reject`, true),
    createReward: ApiConfig.POST(`${BASE_URL}/reward`, true),
    getReward: ApiConfig.GET(`${BASE_URL}/reward`, true),
    updateReward: ApiConfig.PUT(`${BASE_URL}/reward`, true),
    createQr: ApiConfig.POST(`${BASE_URL}/qr`, true),
    getQr: ApiConfig.GET(`${BASE_URL}/qr`, true)
};

export default API;
