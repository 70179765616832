import React from 'react';
import cx from '../../utils/cx';
import { CheckBoxInner, CheckBoxLabel, CheckBoxWrapper } from './index.styled';

interface ICheckBoxProps {
    label?: string,
    onClick?: any,
    checked?: boolean,
    className?: any,
    fontBold?: boolean,
    fontSmall?: boolean,
    fontExtraSmall?: boolean
}

const CheckBox: React.FC<ICheckBoxProps> = (props) => {
    // const { label, onChange, type, value, className, style, block, rounded, fontSmall, fontExtraSmall, disabled } = props;
    // const { label, value, className, style, block, rounded, fontSmall, fontExtraSmall, disabled } = props;
    const { label, onClick, checked, className, fontBold, fontSmall, fontExtraSmall } = props;
    return (
        <CheckBoxWrapper onClick={onClick} className={(cx({ className, fontBold, fontSmall, fontExtraSmall }))}>
            <CheckBoxInner>
                {checked ? (
                    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                    </svg>
                ) : (
                    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
                    </svg>
                )}
            </CheckBoxInner>
            {label && (
                <CheckBoxLabel>{label}</CheckBoxLabel>
            )}
        </CheckBoxWrapper>
    );
};

CheckBox.defaultProps = {
    label: '',
    onClick: undefined,
    checked: false,
    className: '',
    fontBold: false,
    fontSmall: false,
    fontExtraSmall: false
};

export default CheckBox;
