import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../../components/Card';
import Image from '../../../../components/Image';
import Main from '../../../../layouts/Main';
import logo from '../../../../assets/img/logo-sharp-red.png';
import Button from '../../../../components/Button';
import InputText from '../../../../components/InputText';
import SelectInput from '../../../../components/SelectInput';
import programGopayDynabook from '../../../../constant/form/programGopayDynabook';
import programGopaySmartphone from '../../../../constant/form/programGopaySmartphone';
import promoGaransiZfix from '../../../../constant/form/promoGaransiZfix';
import japanTrip from '../../../../constant/form/japanTrip';

const genderOpt = [
    { title: 'Laki-laki', value: 'male' },
    { title: 'Perempuan', value: 'female' }
];

const VoucherRedeem: React.FC = () => {
    const { program } = useParams();
    const navigate = useNavigate();
    const [formField, setFormField] = useState<any>({});
    const [name, setName] = useState('');
    const [gender, setGender] = useState<any>({});
    const [phone, setPhone] = useState('');
    const [serialOrImei, setSerialOrImei] = useState('');
    const [serialImage, setSerialImage] = useState<any>({});
    const [model, setModel] = useState('');
    const [identity, setIdentity] = useState('');
    const [receipt, setReceipt] = useState<any>({});

    useEffect(() => {
        if (program === 'promo-garansi-zfix') {
            setFormField(promoGaransiZfix);
            return;
        }
        if (program === 'japan-trip') {
            setFormField(japanTrip);
            return;
        }
        if (program === 'program-gopay-smartphone') {
            setFormField(programGopaySmartphone);
            return;
        }
        if (program === 'program-gopay-dynabook') {
            setFormField(programGopayDynabook);
            return;
        }
        navigate('/404');
    }, [program]);

    useEffect(() => {
        console.log(serialImage);
        console.log(identity);
        console.log(receipt.File);
    }, [identity, receipt]);

    return (
        <Main>
            <div className="logo">
                <Image src={logo} />
            </div>
            <Card shadow>
                {formField.name ? (
                    <InputText
                        block
                        border
                        label={formField.name_label}
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    />
                ) : null}
                {formField.gender ? (
                    <SelectInput
                        label={formField.gender_label}
                        options={genderOpt}
                        value={gender.title}
                        onOptionSelected={(value: string) => setGender(value)}
                    />
                ) : null}
                {formField.phone ? (
                    <InputText
                        block
                        border
                        label={formField.phone_label}
                        value={phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                    />
                ) : null}
                {formField.serial_or_imei ? (
                    <InputText
                        block
                        border
                        label={formField.serial_or_imei_label}
                        value={serialOrImei}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSerialOrImei(e.target.value)}
                    />
                ) : null}
                {formField.identity_number ? (
                    <InputText
                        block
                        border
                        label={formField.identity_number_label}
                        value={identity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIdentity(e.target.value)}
                    />
                ) : null}
                {formField.model ? (
                    <InputText
                        block
                        border
                        label={formField.model_label}
                        value={model}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModel(e.target.value)}
                    />
                ) : null}

                {formField.serial_image ? (
                    <InputText
                        block
                        border
                        label={formField.serial_image_label}
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSerialImage(e.target?.files)}
                    />
                ) : null}
                {formField.receipt ? (
                    <InputText
                        block
                        border
                        label={formField.receipt_label}
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReceipt(e.target?.files)}
                    />
                ) : null}
                <Button block fullWidth primary className="mt-3 animate-rounded">SUBMIT</Button>
            </Card>
        </Main>
    );
};

export default VoucherRedeem;
