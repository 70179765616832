import React from 'react';
import cx from '../../utils/cx';
import Text from '../Text';
import { ModalWrapper } from './index.styled';

interface IModalProps {
    children: any,
    title?: string,
    visibility?: boolean,
    // eslint-disable-next-line no-unused-vars
    modalHandler?: (visibility: boolean) => void | any,
    className?: string,
    style?: React.CSSProperties,
    outerClick?: boolean
}

const Modal: React.FC<IModalProps> = (props) => {
    const { children, title, visibility, modalHandler, className, style, outerClick } = props;

    const onModalClose = () => {
        if (modalHandler) {
            modalHandler(false);
        }
    };

    return (
        <>
            <div id="overlay" className={cx({ visibility })} onClick={outerClick ? () => onModalClose() : () => {}} />
            <ModalWrapper style={style} className={cx({ className, visibility })}>
                {title ? (
                    <Text fontBold h6 className="mb-3">{title}</Text>
                ) : null}
                {children}
            </ModalWrapper>
        </>
    );
};

Modal.defaultProps = {
    title: '',
    modalHandler: () => { },
    visibility: false,
    className: '',
    style: undefined,
    outerClick: false
};

export default Modal;
