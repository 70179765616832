import React from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../../components/Loading';
import Toast from '../../components/Toast';
import { useAppSelector } from '../../hooks/useRedux';
import { ContentWrapper, MainWrapper } from './index.styled';

interface IMainProps {
    children: React.ReactNode | string
}

const Main: React.FC<IMainProps> = (props) => {
    const { children } = props;
    const { theme, loading, toastMessage, toastStatus, toastVisible } = useAppSelector((state) => state.uiReducer);
    return (
        <>
            <Helmet>
                <html lang="id" data-theme-mode={theme} />
            </Helmet>
            <Toast position="top-right" visibility={toastVisible} status={toastStatus}>{toastMessage}</Toast>
            <MainWrapper>
                <ContentWrapper>
                    {children}
                </ContentWrapper>
            </MainWrapper>
            <Loading visibility={loading} />
        </>
    );
};

export default Main;
