import styled from 'styled-components';
import { CardWrapper } from '../../components/Card/index.styled';

const MainWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    max-width: 480px;
    margin: auto;
    box-shadow: 0 6px 13px 5px rgba(0, 0, 0, .08);

    @media only screen and (max-width: 768px) {
        max-width: none;
    }

`;

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    background: var(--default-bg);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;

    @media only screen and (max-width: 768px) {
        padding: 2rem 2.5rem;
    }

    & > .logo {
        position: relative;
        display: block;
        height: 32px;
        text-align: center;
        width: 100%;
        margin: 0 0 40px;

        img {
            height: 100%;
        }
    }

    &::before {
        content: '';
        position: fixed;
        background: var(--default-primary);
        display: block;
        width: 100%;
        height: 300px;
        max-width: 480px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 60% 15% 0 0;
        z-index: 0;
        
        @media only screen and (max-width: 768px) {
            max-width: none;
        }
    }

    ${CardWrapper} {
        width: 100%;
        height: auto;
        padding: 1.7rem;
        z-index: 1;

        & > .logo {
            position: relative;
            display: block;
            height: 32px;
            text-align: center;
            width: 100%;
            margin: 0 0 15px;

            img {
                height: 100%;
            }
        }
    }
`;

export {
    MainWrapper,
    ContentWrapper
};
