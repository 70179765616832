import styled from 'styled-components';

const CardWrapper = styled.div<{shadow?: boolean}>`
    position: relative;
    display: block;
    width: auto;
    padding: 1rem;
    background: var(--default-bg);
    color: var(--default-fg);
    border-radius: 10px;
    ${(props) => (props.shadow ? 'box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;' : '')}
`;

export {
    CardWrapper
};
