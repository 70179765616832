import moment from 'moment';

const friendlyDate = (value: string): string | Date => {
    const date = moment(new Date(value)).format('YYYY-MM-DD HH:mm');
    return date;
};

const getDate = (value: string): string | Date => {
    const date = moment(new Date(value)).format('YYYY-MM-DD');
    return date;
};

export default { friendlyDate, getDate } as const;
