import React from 'react';
import Main from '../../../../layouts/Main';

const VoucherRedeem: React.FC = () => {
    return (
        <Main>
            masin
        </Main>
    );
};

export default VoucherRedeem;
