import styled from 'styled-components';

const ToastWrapper = styled.div<{ topCenter?: boolean, topLeft?: boolean, topRight?: boolean, bottomCenter?: boolean, bottomLeft?: boolean, bottomRight?: boolean }>`
    position: fixed;
    display: block;
    background: rgba(0, 0, 0, .2);
    padding: 5px 10px;
    color: var(--default-fg);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 10px;
    user-select: none;
    transition: .3s ease-in-out;
    z-index: 101;

    ${(props) => (props.topCenter ? `
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        &.visible {
            left: 1.2rem;
        }
    ` : '')}
    ${(props) => (props.topLeft ? `
        top: 30px;
        left: -110%;
        &.visible {
            left: 1.2rem;
        }
    ` : '')}
    ${(props) => (props.topRight ? `
        top: 30px;
        right: -110%;
        &.visible {
            right: 1.2rem;
        }
    ` : '')}
    ${(props) => (props.bottomCenter ? `
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    ` : '')}
    ${(props) => (props.bottomLeft ? `
        bottom: 30px;
        left: -110%;
        &.visible {
            left: 1.2rem;
        }
    ` : '')}
    ${(props) => (props.bottomRight ? `
        bottom: 30px;
        right: -110%;

        &.visible {
            right: 1.2rem;
        }
    ` : '')}

    &.status-error {
        background: var(--color-error);
        color: var(--color-white);
    }

    &.status-success {
        background: var(--color-success);
    }

    &.status-warning {
        background: var(--color-warning);
        color: var(--color-black);
    }
`;

export {
    ToastWrapper
};
