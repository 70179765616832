import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppSelector } from './hooks/useRedux';
import Campaign from './pages/Campaign';
import Category from './pages/Category';
import Dashboard from './pages/Dashboard';
import SmartphoneForm from './pages/Forms/Smartphone';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Product from './pages/Product';
import AddProduct from './pages/Product/views/AddProduct';
import EditProduct from './pages/Product/views/EditProduct';
import ProductList from './pages/Product/views/ProductList';
import QRCode from './pages/QRCode';
import Reward from './pages/Reward';
import Transaction from './pages/Transaction';
import TransactionList from './pages/Transaction/view/TransactionList';
import TransactionDetail from './pages/TransactionDetail';
import User from './pages/User';
import VoucherCategory from './pages/Voucher/v1/VoucherCategory';

/**
 * Voucher V1
 */
import VoucherProgram from './pages/Voucher/v1/VoucherProgram';
import VoucherRedeemV1 from './pages/Voucher/v1/VoucherRedeem';

/**
 * Voucher V2
 */
import VoucherAuth from './pages/Voucher/v2/VoucherAuth';
import VoucherRedeemV2 from './pages/Voucher/v2/VoucherRedeem';
import TransactionCheck from './pages/Transaction/view/TransactionCheck';
import Branch from './pages/Branch';
import AudioForm from './pages/Forms/Audio';
import Mutation from './pages/Mutation';

interface IPrivateRoute {
    children: any
}

const PrivateRoute: React.FC<IPrivateRoute> = (props) => {
    const { children } = props;
    const { loggedIn } = useAppSelector((state) => state.authReducer);

    if (!loggedIn) {
        return <Navigate to="/login" replace />;
    }
    return children;
};

const App: React.FC = () => {
    return (
        <Routes>
            <Route
                index
                element={<AudioForm />}
            />
            <Route
                path="/login"
                element={<Login />}
            />
            <Route
                path="/admin"
                element={(
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/product"
                element={(
                    <PrivateRoute>
                        <Product />
                    </PrivateRoute>
                )}
            >
                <Route
                    index
                    element={<ProductList />}
                />
                <Route
                    path=":status"
                    element={<ProductList />}
                />
            </Route>
            <Route
                path="/admin/product/add"
                element={(
                    <PrivateRoute>
                        <AddProduct />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/product/edit/:product_id"
                element={(
                    <PrivateRoute>
                        <EditProduct />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/campaign"
                element={(
                    <PrivateRoute>
                        <Campaign />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/category"
                element={(
                    <PrivateRoute>
                        <Category />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/branch"
                element={(
                    <PrivateRoute>
                        <Branch />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/mutation"
                element={(
                    <PrivateRoute>
                        <Mutation />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/transaction"
                element={(
                    <PrivateRoute>
                        <Transaction />
                    </PrivateRoute>
                )}
            >
                <Route
                    index
                    element={<TransactionList />}
                />
                <Route
                    path=":status"
                    element={<TransactionList />}
                />
                <Route
                    path="status"
                    element={<TransactionCheck />}
                />
            </Route>
            <Route
                path="/admin/transaction/detail/:trx_id"
                element={(
                    <PrivateRoute>
                        <TransactionDetail />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/qr-code"
                element={(
                    <PrivateRoute>
                        <QRCode />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/reward"
                element={(
                    <PrivateRoute>
                        <Reward />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/user"
                element={(
                    <PrivateRoute>
                        <User />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/form/smartphone"
                element={<SmartphoneForm />}
            />
            <Route
                path="/form/audio"
                element={<AudioForm />}
            />
            <Route
                path="/v1/voucher/program"
                element={<VoucherProgram />}
            />
            <Route
                path="/v1/voucher/category"
                element={<VoucherCategory />}
            />
            <Route
                path="/v1/voucher/redeem/:program"
                element={<VoucherRedeemV1 />}
            />
            <Route
                path="/v2/voucher/:voucher_serial_number"
                element={<VoucherAuth />}
            />
            <Route
                path="/v2/voucher/redeem"
                element={<VoucherRedeemV2 />}
            />
            <Route
                path="*"
                element={<NotFound />}
            />
        </Routes>
    );
};

export default App;
