import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import Image from '../../../components/Image';
import Main from '../../../layouts/Main';
import logo from '../../../assets/img/logo-sharp-red.png';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import SelectInput from '../../../components/SelectInput';
import CheckBox from '../../../components/CheckBox';
import Text from '../../../components/Text';
import imeiScreenshot from '../../../assets/img/imei-screenshot.jpg';
import './index.scss';
import useProduct from '../../../hooks/useProduct';
import API from '../../../configs/api';
import { useAppDispatch } from '../../../hooks/useRedux';
import { hideLoading, showLoading, showToast } from '../../../redux/action';
import useBranch from '../../../hooks/useBranch';

const genderOpt = [
    { id: 0, title: 'Laki-laki', value: 'LAKI-LAKI' },
    { id: 1, title: 'Perempuan', value: 'PEREMPUAN' }
];

const SmartphoneForm: React.FC = () => {
    const category_id = 1;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const products = useProduct({ currentPage: 1, limit: 1000, category_id, status: 'ACTIVE' });
    const branchs = useBranch(1, 1000);
    const [modelOpt, setModelOpt] = useState<any>([]);
    const [branchOpt, setBranchOpt] = useState<any>([]);
    const [name, setName] = useState('');
    const [gender, setGender] = useState<any>({});
    const [phone, setPhone] = useState('');
    const [ewalletNumber, setEwalletNumber] = useState('');
    const [model, setModel] = useState<any>({});
    const [imei, setImei] = useState('');
    const [serialImage, setSerialImage] = useState<any>({});
    const [identity, setIdentity] = useState('');
    const [receipt, setReceipt] = useState<any>({});
    const [campaigns, setCampaigns] = useState<any[]>([]);
    const [campaignSelected, setCampaignSelected] = useState<any[]>([]);
    const [store, setStore] = useState('');
    const [branchSelected, setBranchSelected] = useState<any>({});

    useEffect(() => {
        // const opt = [];
        if (products.list < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < products.list.length; i++) {
            const obj = {
                title: products.list[i]?.model,
                value: products.list[i]?.model,
                product_id: products.list[i]?.id
            };
            setModelOpt((e: any) => [...e, obj]);
        }
    }, [products.list, products.loading]);

    useEffect(() => {
        // const opt = [];
        if (branchs.list < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < branchs.list.length; i++) {
            const obj = {
                title: branchs.list[i]?.name,
                value: branchs.list[i]?.name,
                id: branchs.list[i]?.id
            };
            setBranchOpt((e: any) => [...e, obj]);
        }
    }, [branchs.list, branchs.loading]);

    useEffect(() => {
        if (!model.product_id) return;
        dispatch(showLoading());
        const payload = {
            params: `/${model.product_id}`
        };

        API.getProduct(payload).then((result: any) => {
            dispatch(hideLoading());
            setCampaigns(result.data.product_campaigns);
        });
    }, [model?.product_id]);

    const checkForm = (): boolean => {
        if (!name) {
            dispatch(showToast('Nama belum diisi', 'warning'));
            return true;
        }
        if (!gender.value) {
            dispatch(showToast('Pilih jenis kelamin', 'warning'));
            return true;
        }
        if (!model.product_id) {
            dispatch(showToast('Pilih model smartpone', 'warning'));
            return true;
        }
        if (!phone) {
            dispatch(showToast('No HP belum diisi', 'warning'));
            return true;
        }
        if (phone.length < 8) {
            dispatch(showToast('Masukkan No HP yang valid', 'warning'));
            return true;
        }
        if (!ewalletNumber) {
            dispatch(showToast('No Ewallet belum diisi', 'warning'));
            return true;
        }
        if (ewalletNumber.length < 8) {
            dispatch(showToast('Masukkan No Ewallet yang valid', 'warning'));
            return true;
        }
        if (!identity) {
            dispatch(showToast('NIK belum diisi', 'warning'));
            return true;
        }
        if (!imei) {
            dispatch(showToast('IMEI belum diisi', 'warning'));
            return true;
        }
        if (!serialImage.name) {
            dispatch(showToast('Upload foto screenshot IMEI', 'warning'));
            return true;
        }
        if (!receipt.name) {
            dispatch(showToast('Upload foto invoice', 'warning'));
            return true;
        }
        if (!store) {
            dispatch(showToast('Ketik nama toko', 'warning'));
            return true;
        }
        if (campaignSelected.length < 1) {
            dispatch(showToast('Pilih promo', 'warning'));
            return true;
        }
        if (!branchSelected.value) {
            dispatch(showToast('Pilih cabang', 'warning'));
            return true;
        }
        return false;
    };

    const onSubmit = () => {
        if (checkForm()) {
            return;
        }
        dispatch(showLoading());

        const formData = new FormData();
        formData.append('name', name);
        formData.append('gender', gender.value);
        formData.append('phone', phone.split(' ').join(''));
        formData.append('ewallet_number', ewalletNumber.split(' ').join(''));
        formData.append('category_id', category_id.toString());
        formData.append('product_id', model.product_id);
        formData.append('model', model.value);
        formData.append('serial_number', imei);
        formData.append('identity_number', identity);
        formData.append('serial_number_image', serialImage);
        formData.append('receipt', receipt);
        formData.append('campaigns', JSON.stringify(campaignSelected));
        formData.append('store', store);
        formData.append('branch_id', branchSelected.id);

        const payload = {
            content: 'form-data',
            body: formData
        };

        API.createTransaction(payload).then(() => {
            dispatch(showToast('Data berhasil disubmit', 'success'));
            setTimeout(() => {
                dispatch(hideLoading());
                navigate(0);
            }, 1000);
        }).catch((err: any) => {
            dispatch(showToast(err.meta.message, 'warning'));
            dispatch(hideLoading());
        });
    };

    const handleSelectCampaign = (id: string | number) => {
        const existingCampaign = campaignSelected.filter((i: any) => i.campaign_id === id);
        if (existingCampaign.length > 0) {
            const newCampaign = campaignSelected.filter((i: any) => i.campaign_id !== id);
            setCampaignSelected(newCampaign);
            return;
        }
        setCampaignSelected([...campaignSelected, { campaign_id: id }]);
    };

    const checkedCampaign = (id: string | number): boolean => {
        const existingCampaign = campaignSelected.filter((i: any) => i.campaign_id === id);
        if (existingCampaign.length > 0) return true;
        return false;
    };

    return (
        <Main>
            <div className="logo">
                <Image src={logo} />
            </div>
            <Card shadow>
                <InputText
                    block
                    border
                    label="Nama"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                />
                <SelectInput
                    label="Jenis Kelamin"
                    options={genderOpt}
                    value={gender.title}
                    onOptionSelected={(value: any) => setGender(value)}
                />
                {modelOpt.length > 0 ? (
                    <SelectInput
                        label="Model Smartphone"
                        options={modelOpt}
                        value={model.title}
                        optionHeader
                        headerPlaceholder="Ketik model smartphone"
                        onOptionSelected={(value: any) => {
                            setModel(value);
                            setCampaignSelected([]);
                        }}
                    />
                ) : (
                    <InputText border placeholder="Model Smartphone" />
                )}
                <InputText
                    block
                    border
                    label="Nomor WhatsApp"
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                />
                <InputText
                    block
                    border
                    label="Nomor Ewallet (Gopay)"
                    value={ewalletNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEwalletNumber(e.target.value)}
                />
                <InputText
                    block
                    border
                    label="Nomor KTP (NIK)"
                    value={identity}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIdentity(e.target.value)}
                />
                <div id="example-screen">
                    <Text paragraph fontBold>Tutorial cek IMEI di Smartphone Sharp AQUOS V6</Text>
                    <iframe title="Sharp" height="215" src="https://www.youtube.com/embed/u_1QdV3KKCc" />
                </div>
                <div id="example-screen">
                    <Text paragraph fontBold>Contoh Screenshot IMEI di HP</Text>
                    <Image src={imeiScreenshot} alt="Sreenshot imei" />
                </div>
                <InputText
                    block
                    border
                    label="Nomor IMEI Smartphone"
                    value={imei}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImei(e.target.value)}
                />
                <InputText
                    block
                    border
                    label="Foto Screenshot IMEI"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.files) {
                            return;
                        }
                        const file = e.target.files[0];
                        setSerialImage(file);
                    }}
                />
                <InputText
                    block
                    border
                    label="Foto Invoice"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.files) {
                            return;
                        }
                        const file = e.target.files[0];
                        setReceipt(file);
                    }}
                />
                <InputText
                    block
                    border
                    label="Nama Toko Pembelian"
                    value={store}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStore(e.target.value)}
                />
                {branchOpt.length > 0 ? (
                    <SelectInput
                        label="Cabang Pembelian"
                        options={branchOpt}
                        value={branchSelected.title}
                        optionHeader
                        headerPlaceholder="Ketik nama cabang"
                        onOptionSelected={(value: any) => {
                            setBranchSelected(value);
                        }}
                    />
                ) : (
                    <InputText border placeholder="Cabang Pembelian" />
                )}
                <Text paragraph className="mt-3" fontBold>Promo Produk</Text>
                {campaigns.length > 0 && campaigns.map((i: any) => (
                    <CheckBox
                        key={i.id}
                        label={i.campaign_name}
                        checked={checkedCampaign(i.id)}
                        onClick={() => {
                            handleSelectCampaign(i.id);
                        }}
                    />
                ))}
                <Button block fullWidth primary className="mt-3 animate-rounded" onClick={() => onSubmit()}>SUBMIT</Button>
            </Card>
        </Main>
    );
};

export default SmartphoneForm;
