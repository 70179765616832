import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { read, utils } from 'xlsx';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import Modal from '../../../components/Modal';
import API from '../../../configs/api';
import { useAppDispatch } from '../../../hooks/useRedux';
import { hideLoading, showLoading } from '../../../redux/action';

interface IImportDataDialogProps {
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const ImportDataDialog: React.FC<IImportDataDialogProps> = (props) => {
    const { dialogHandler } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);

    const readFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const workbook = read(e.target.result, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = utils.sheet_to_json(worksheet);
                setData(json);
            };
            reader.readAsArrayBuffer(event.target.files[0]);
        }
    };

    const onSubmit = () => {
        dispatch(showLoading());
        const payload = {
            body: {
                items: data
            }
        };
        API.importProduct(payload).then(() => {
            dispatch(hideLoading());
            navigate(0);
        }).catch(() => {
            dispatch(hideLoading());
        });
    };

    return (
        <Modal title="Import Product" visibility modalHandler={dialogHandler}>
            <InputText
                block
                border
                label="File"
                type="file"
                onChange={readFileUpload}
            />
            <div className="row mt-3">
                <div className="col-6">
                    <Button block fullWidth borderPrimary onClick={() => dialogHandler(false)}>Cancel</Button>
                </div>
                <div className="col-6">
                    <Button block fullWidth primary onClick={() => onSubmit()}>Import</Button>
                </div>
            </div>
        </Modal>
    );
};

export default ImportDataDialog;
