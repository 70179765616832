import classNames from 'classnames';

interface ICx {
    className?: string,
    gray?: boolean,
    block?: boolean,
    link?: boolean,
    fullWidth?: boolean,
    fontBold?: boolean,
    fontExtraBold?: boolean,
    fontSmall?: boolean,
    fontExtraSmall?: boolean,
    fontLarge?: boolean,
    border?: boolean,
    borderHover?: boolean,
    gradient?: boolean,
    primary?: boolean,
    borderPrimary?: boolean,
    secondary?: boolean,
    rounded?: boolean,
    status?: string,
    active?: boolean,
    visibility?: boolean,
    paragraph?: boolean,
    h1?: boolean,
    h2?: boolean,
    h3?: boolean,
    h4?: boolean,
    h5?: boolean,
    h6?: boolean
}

const cx = (name: ICx) => classNames(name.className, {
    block: name.block,
    gray: name.gray,
    'is-link': name.link,
    'full-width': name.fullWidth,
    'fw-bold': name.fontBold,
    'fw-extrabold': name.fontExtraBold,
    'fz-small': name.fontSmall,
    'fz-extrasmall': name.fontExtraSmall,
    'fz-large': name.fontLarge,
    border: name.border,
    'border-hover': name.borderHover,
    primary: name.primary,
    secondary: name.secondary,
    'status-error': name.status === 'error',
    'status-success': name.status === 'success',
    'status-warning': name.status === 'warning',
    active: name.active,
    'bg-gradient': name.gradient,
    'border-primary': name.borderPrimary,
    rounded: name.rounded,
    visible: name.visibility,
    paragraph: name.paragraph,
    h1: name.h1,
    h2: name.h2,
    h3: name.h3,
    h4: name.h4,
    h5: name.h5,
    h6: name.h6
});

export default cx;
