import React from 'react';
import styled from 'styled-components';
// import Icon from './Icon';
// import Button from './Button';
// import Text from './Text';
// import HeaderMenuList from './HeaderMenuList';

const HeaderWrapper = styled.header`
    position: fixed;
    display: block;
    width: 100%;
    max-width: calc(100% - (20% + -20px));
    height: auto;
    top: 0;
    right: 0;
    border-radius: 25px 0 0 0;
    background: var(--default-bg);
    transition: max-width .3s ease;
    border-bottom: 1px solid var(--default-border);
    z-index: 91;

    &.sidebar-minimize {
        max-width: calc(100% - 80px);
    }

    @media only screen and (max-width: 768px) {
        border-radius: 0;
        max-width: 100%;
        
        &.sidebar-minimize {
            max-width: 100%;
        }
    }
`;

const HeaderItemWrapper = styled.div`
    position: relative;
    display: flex;
    padding: 0 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
        padding: 0 1rem;
    }
`;

const HeaderItem = styled.div`
    position: relative;
    display: inline-block;
    height: 100%;
    padding: 10px 0;

    .logo {
        position: relative;
        display: block;
        height: 22px;

        img {
            height: 100%;
        }
    }
`;

const BurgerBtn = styled.div`
    position: relative;
    display: flex;
    width: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    gap: 6px;
    padding: 5px 0;
    cursor: pointer;
    z-index: 10001;

    i {
        position: relative;
        height: 2px;
        width: 100%;
        right: 0;
        background: var(--default-primary);
        transition: .3s ease;
        
        &:nth-of-type(1) {
            width: 50%;
        }
        &:nth-of-type(3) {
            width: 70%;
        }

    }

    &.sidebar-minimize > i {
        width: 100% !important;
    }
`;

// const ThemeChange = () => {
//     const { theme } = useAppSelector((state) => state.uiReducer);
//     const dispatch = useAppDispatch();

//     const onThemeSwitch = () => {
//         if (theme === 'light') {
//             dispatch(setThemeDark());
//             return;
//         }
//         dispatch(setThemeLight());
//     };

//     return (
//         <Button onClick={() => onThemeSwitch()}>{theme}</Button>
//     );
// };

interface IHeaderProps {
    openSidebar: boolean,
    // eslint-disable-next-line no-unused-vars
    sidebarHandler: (visible: boolean) => void
}

const Header: React.FC<IHeaderProps> = (props) => {
    const { openSidebar, sidebarHandler } = props;

    return (
        <HeaderWrapper id="header-component" className={openSidebar ? '' : 'sidebar-minimize'}>
            <HeaderItemWrapper>
                <HeaderItem style={{ width: '100%', textAlign: 'left' }}>
                    <BurgerBtn className={openSidebar ? '' : 'sidebar-minimize'} onClick={() => sidebarHandler(!openSidebar)}>
                        <i />
                        <i />
                        <i />
                    </BurgerBtn>
                </HeaderItem>
                {/* <HeaderItem style={{ width: '65%' }}>
                    <HeaderMenuList />
                </HeaderItem> */}
                <HeaderItem style={{ width: '100%', textAlign: 'right' }}>
                    {/* <Icon onClick={() => { }} name="scan" />
                    <Icon onClick={() => { }} name="profile" /> */}
                    {/* <Button block fullWidth secondary className="build-happiness animate-rounded">
                        <Text fontBold primary>Build Happiness</Text>
                    </Button> */}
                </HeaderItem>
            </HeaderItemWrapper>
        </HeaderWrapper>
    );
};

export default Header;
