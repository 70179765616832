import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Badge from '../../../components/Badge';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import InputText from '../../../components/InputText';
import SelectInput from '../../../components/SelectInput';
import Table from '../../../components/Table';
import useDate from '../../../helpers/useDate';
import useCategory from '../../../hooks/useCategory';
import useTransaction from '../../../hooks/useTransaction';
import ExportDialog from '../components/ExportDialog';
import { useAppSelector } from '../../../hooks/useRedux';

const TransactionList: React.FC = () => {
    const { profile } = useAppSelector((state) => state.authReducer);
    const { status } = useParams();

    const category = useCategory(1, 1000);

    const [categoryOpt, setCategoryOpt] = useState<any[]>([]);
    const [categorySelected, setCategorySeleted] = useState<any>({});

    const [filterSearch, setFilterSearch] = useState('');
    const [filterCategory, setFilterCategory] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const { list, totalList, loading } = useTransaction({ currentPage, limit: 10, status, category: filterCategory, search: filterSearch, whereIn: profile.category_id });
    const [totalRow, setTotalRow] = useState(0);
    const [exportDialog, setExportDialog] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        if (totalList > 0) {
            setTotalRow(totalList);
        }
    }, [list]);

    useEffect(() => {
        setCurrentPage(1);
    }, [status]);

    const columns: any[] = [
        {
            name: '#',
            sortable: true,
            width: '70px',
            selector: (row: any) => row.id
        },
        {
            name: 'Created At',
            sortable: true,
            width: '120px',
            selector: (row: any) => useDate.friendlyDate(row.created_at)
        },
        {
            name: 'Category',
            sortable: true,
            selector: (row: any) => row.category_name
        },
        {
            name: 'Name',
            sortable: true,
            selector: (row: any) => row.customer_name
        },
        {
            name: 'Model',
            sortable: true,
            selector: (row: any) => row.model
        },
        {
            name: 'Phone',
            sortable: true,
            selector: (row: any) => row.customer_phone
        },
        {
            name: 'Serial Number',
            sortable: true,
            selector: (row: any) => row.serial_number
        },
        {
            name: 'Status',
            sortable: true,
            selector: (row: any) => (
                <>
                    {row.status === 'PENDING' && (
                        <Badge status="warning">{row.status}</Badge>
                    )}
                    {row.status === 'APPROVED' && (
                        <Badge status="success">{row.status}</Badge>
                    )}
                    {row.status === 'REJECT' && (
                        <Badge status="error">{row.status}</Badge>
                    )}
                </>
            )
        },
        {
            cell: (row: any) => <Button borderPrimary link to={`/admin/transaction/detail/${row.trx_id}`} className="btn-small">VIEW</Button>,
            style: {
                justifyContent: 'center'
            }
        }
    ];

    useEffect(() => {
        if (category.list < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < category.list.length; i++) {
            const obj = {
                title: category.list[i]?.name,
                value: category.list[i]?.id
            };
            setCategoryOpt((e: any) => [...e, obj]);
        }
    }, [category.list]);

    useEffect(() => {
        if (searchKeyword.length < 1) {
            setFilterSearch('');
        }
    }, [searchKeyword]);

    const onSearchSubmit = () => {
        setFilterSearch(searchKeyword);
    };

    return (
        <>
            <div className="row align-items-center my-3">
                <div className="col-4" />
                <div className="col-3">
                    <InputText
                        border
                        placeholder="Search"
                        value={searchKeyword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchKeyword(e.target.value)}
                        iconRight="search"
                        iconRightOnClick={() => onSearchSubmit()}
                    />
                </div>
                <div className="col-3">
                    {categoryOpt.length > 0 ? (
                        <SelectInput
                            value={categorySelected.title}
                            options={categoryOpt}
                            placeholder="Category"
                            onOptionSelected={(value: any) => {
                                setCategorySeleted(value);
                                setFilterCategory(value.value);
                            }}
                        />
                    ) : (
                        <InputText label="Category" />
                    )}
                </div>
                <div className="col-2">
                    <Button block primary fullWidth onClick={() => setExportDialog(true)}>Export</Button>
                </div>
            </div>
            <Card border>
                <Table
                    data={list}
                    columns={columns}
                    loading={loading}
                    paginate
                    currentPage={currentPage}
                    totalList={totalRow}
                    onPageChange={(e) => {
                        setCurrentPage(e.selected + 1);
                    }}
                />
            </Card>
            {exportDialog && (
                <ExportDialog dialogHandler={(visible: boolean) => setExportDialog(visible)} />
            )}
        </>
    );
};

export default TransactionList;
