import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import Modal from '../../../components/Modal';
import SelectInput from '../../../components/SelectInput';
import API from '../../../configs/api';
import generateUniqueId from '../../../helpers/generateUniqueId';
import useDate from '../../../helpers/useDate';
import useCategory from '../../../hooks/useCategory';
import { useAppDispatch } from '../../../hooks/useRedux';
import { hideLoading, showLoading, showToast } from '../../../redux/action';

interface IAddCampaignDialog {
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const AddCampaignDialog: React.FC<IAddCampaignDialog> = (props) => {
    const { dialogHandler } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { list, loading } = useCategory(1, 1000);
    const [name, setName] = useState('');
    const [categoryOpt, setCategoryOpt] = useState<any>([]);
    const [categorySelected, setCategorySeleted] = useState<any>({});
    const [campaignCode, setCampaignCode] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const generateCampaignCode = () => {
        const code = generateUniqueId('SHP', 9);
        setCampaignCode(code);
    };

    useEffect(() => {
        const opt = [];
        if (!loading) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < list.length; i++) {
                const obj = {
                    title: list[i]?.name,
                    value: list[i]?.id
                };
                opt.push(obj);
                // setCategoryOpt((e: any) => [...e, obj]);
            }
            setCategoryOpt(opt);
        }
    }, [list, loading]);

    const handleCreate = () => {
        const payload = {
            body: {
                name,
                campaign_code: campaignCode,
                category_id: categorySelected.value,
                start_date: useDate.friendlyDate(startDate),
                end_date: useDate.friendlyDate(endDate)
            }
        };
        dispatch(showLoading());
        API.createCampaign(payload).then((result: any) => {
            dispatch(hideLoading());
            dispatch(showToast(result.meta.message, 'success'));
            navigate(0);
        }).catch(() => {
            dispatch(hideLoading());
            dispatch(showToast('Failed', 'error'));
        });
    };

    return (
        <Modal
            title="Add Campaign"
            visibility
            modalHandler={dialogHandler}
        >
            <InputText
                label="Name"
                block
                border
                type="text"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
            <SelectInput
                label="Type / Category"
                optionHeader
                headerPlaceholder="Category ..."
                placeholder="Select Category / Type"
                options={categoryOpt}
                value={categorySelected.title}
                onOptionSelected={(e: any) => {
                    setCategorySeleted(e);
                }}
            />
            <InputText
                label="Campaign Code"
                iconRight="swap-2"
                iconRightOnClick={() => generateCampaignCode()}
                block
                border
                type="text"
                value={campaignCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCampaignCode(e.target.value)}
            />
            <div className="row mt-2">
                <div className="col-6">
                    <InputText
                        label="Start Date"
                        block
                        border
                        type="date"
                        value={startDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-6">
                    <InputText
                        label="End Date"
                        block
                        border
                        type="date"
                        value={endDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
                    />
                </div>
            </div>
            <div id="modal-footer" className="row mt-3">
                <div className="col-6">
                    <Button block fullWidth borderPrimary onClick={() => dialogHandler(false)}>Cancel</Button>
                </div>
                <div className="col-6">
                    <Button block fullWidth primary onClick={() => handleCreate()}>Add</Button>
                </div>
            </div>
        </Modal>
    );
};

export default AddCampaignDialog;
