import React from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Sidebar from '../../components/Sidebar';
import Toast from '../../components/Toast';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { closeSidebar, openSidebar } from '../../redux/action';
import { AdminContent, AdminWrapper } from './index.styled';

interface IAdminProps {
    children: any,
    activeMenu?: string,
    hasTab?: boolean
}

const Admin: React.FC<IAdminProps> = (props) => {
    const { children, activeMenu, hasTab } = props;
    const { theme, sidebar, loading, toastMessage, toastStatus, toastVisible } = useAppSelector((state) => state.uiReducer);
    const dispatch = useAppDispatch();
    const fullScreen = useFullScreenHandle();

    const handleSidebar = () => {
        if (sidebar) {
            dispatch(closeSidebar());
            return;
        }
        dispatch(openSidebar());
    };

    return (
        <>
            <Helmet>
                <html lang="id" data-theme-mode={theme} />
            </Helmet>
            <FullScreen handle={fullScreen}>
                <Header openSidebar={sidebar} sidebarHandler={() => handleSidebar()} />
                <Sidebar activeMenu={activeMenu} />
                <Toast position="top-right" visibility={toastVisible} status={toastStatus}>{toastMessage}</Toast>
                <AdminWrapper className={sidebar ? '' : 'sidebar-minimize'}>
                    <AdminContent className={hasTab ? 'has-tab' : ''}>
                        {children}
                    </AdminContent>
                </AdminWrapper>
            </FullScreen>
            <Loading visibility={loading} />
        </>
    );
};

Admin.defaultProps = {
    activeMenu: undefined,
    hasTab: false
};

export default Admin;
