import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Table from '../../components/Table';
import useBranch from '../../hooks/useBranch';
import Admin from '../../layouts/Admin';
import AddBranchDialog from './components/AddBranchDialog';

const Branch: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const { list, totalList, loading } = useBranch(currentPage, 10);

    const [addBranchDialog, setAddBranchDialog] = useState(false);

    const [totalRow, setTotalRow] = useState(0);

    useEffect(() => {
        if (totalList > 0) {
            setTotalRow(totalList);
        }
    }, [list]);

    const columns: any[] = [
        {
            name: 'ID',
            sortable: true,
            width: '120px',
            selector: (row: any) => row.id
        },
        {
            name: 'Name',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.name
        }
    ];

    return (
        <Admin activeMenu="branch">
            <div className="row mb-3">
                <div className="col-6" />
                <div className="col-6 text-end">
                    <Button primary onClick={() => setAddBranchDialog(true)}>+ Add</Button>
                </div>
            </div>
            <Card border>
                <Table
                    data={list}
                    columns={columns}
                    loading={loading}
                    paginate
                    currentPage={currentPage}
                    totalList={totalRow}
                    onPageChange={(e) => {
                        setCurrentPage(e.selected + 1);
                    }}
                />
            </Card>
            {addBranchDialog && (
                <AddBranchDialog dialogHandler={(visible: boolean) => setAddBranchDialog(visible)} />
            )}
        </Admin>
    );
};

export default Branch;
