import React from 'react';
import Admin from '../../layouts/Admin';

const User: React.FC = () => {
    return (
        <Admin activeMenu="user">
            ksdsd
        </Admin>
    );
};

export default User;
