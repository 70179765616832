import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import Admin from '../../layouts/Admin';

const tabs = [
    { id: 0, title: 'ALL', key: 'all', link: '/admin/product' },
    { id: 1, title: 'ACTIVE', key: 'active', link: '/admin/product/active' },
    { id: 2, title: 'INACTIVE', key: 'inactive', link: '/admin/product/inactive' }
];

const Product: React.FC = () => {
    const { status } = useParams();

    return (
        <Admin activeMenu="product" hasTab>
            <Tabs tabItems={tabs} link defaultTabActive={status || 'all'} />
            <Outlet />
        </Admin>
    );
};

export default Product;
