import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import Button from '../../components/Button';
import Card from '../../components/Card';
import Table from '../../components/Table';
import Text from '../../components/Text';
import useCurrency from '../../helpers/useCurrency';
// import { useAppSelector } from '../../hooks/useRedux';
import Admin from '../../layouts/Admin';
import useMutation from '../../hooks/useMutation';
import InputText from '../../components/InputText';
import SelectInput from '../../components/SelectInput';

const codeOption = [
    {

        title: 'TOP UP',
        value: 'TOPUP'
    },
    {

        title: 'TRANSAKSI EVOUCHER',
        value: 'TRANSAKSI_EVOUCHER'
    }
];

const Mutation: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [codeSelected, setCodeSelected] = useState<any>({});
    const [searchKeyword, setSearchKeyword] = useState('');
    const [filterSearch, setFilterSearch] = useState('');
    const [filterCode, setFilterCode] = useState('');

    const { list, totalList, loading } = useMutation({ currentPage, limit: 10, search: filterSearch, code: filterCode || '' });

    const [totalRow, setTotalRow] = useState(0);

    useEffect(() => {
        if (totalList > 0) {
            setTotalRow(totalList);
        }
    }, [list]);

    const columns: any[] = [
        {
            name: 'ID',
            sortable: true,
            width: '80px',
            selector: (row: any) => row.id
        },
        {
            name: 'ID Transaksi',
            sortable: true,
            selector: (row: any) => row.idTransaksi || '-'
        },
        {
            name: 'Kode',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => <Text style={{ color: 'var(--default-primary)' }} fontSmall>{row.kode}</Text>
        },
        {
            name: 'In',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => `Rp. ${useCurrency(row.in)}`
        },
        {
            name: 'Out',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => `Rp. ${useCurrency(row.out)}`
        },
        {
            name: 'Description',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.keterangan
        },
        {
            name: 'Created At',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => moment(new Date(row.created_at)).format('YYYY-MM-DD')
        }
    ];

    useEffect(() => {
        if (searchKeyword.length < 1) {
            setFilterSearch('');
        }
    }, [searchKeyword]);

    const onSearchSubmit = () => {
        setFilterSearch(searchKeyword);
    };

    return (
        <Admin activeMenu="mutatuon">
            <div className="row align-items-center my-3">
                <div className="col-6" />
                <div className="col-3">
                    <InputText
                        border
                        placeholder="Search"
                        value={searchKeyword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchKeyword(e.target.value)}
                        iconRight="search"
                        iconRightOnClick={() => onSearchSubmit()}
                    />
                </div>
                <div className="col-3">
                    <SelectInput
                        value={codeSelected.title}
                        options={codeOption}
                        placeholder="Code"
                        onOptionSelected={(value: any) => {
                            setCodeSelected(value);
                            setFilterCode(value.value);
                        }}
                    />
                </div>
            </div>
            <Card border>
                <Table
                    data={list}
                    columns={columns}
                    loading={loading}
                    paginate
                    currentPage={currentPage}
                    totalList={totalRow}
                    onPageChange={(e) => {
                        setCurrentPage(e.selected + 1);
                    }}
                />
            </Card>
        </Admin>
    );
};

export default Mutation;
