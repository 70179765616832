import styled from 'styled-components';

const AuthWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: var(--default-canvas);
    transition: max-width .3s ease;

`;

const AuthBanner = styled.div`
    position: relative;
    display: block;
    width: 60%;
    height: 100vh;
    padding: 1rem;
    background: var(--default-primary);

    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const AuthContent = styled.div`
    position: relative;
    display: block;
    width: 40%;
    height: 100%;
    padding: 0 3rem;

    #logo {
        display: block;
        height: 20px;
        margin-bottom: 40px;
        text-align: center;

        img {
            height: 100%;
        }
    }
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 3rem;
    }
`;

export {
    AuthWrapper,
    AuthBanner,
    AuthContent
};
