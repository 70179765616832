import styled from 'styled-components';

const ModalWrapper = styled.div`
    position: fixed;
    display: block;
    width: auto;
    height: auto;
    min-width: 480px;
    max-width: 60%;
    max-height: 90vh;
    padding: 1rem;
    background: var(--default-bg);
    border-radius: 10px;
    bottom: -120%;
    left: 50%;
    overflow-y: auto;

    transform: translate(-50%, 50%);
    transition: .3s ease;
    z-index: 102;

    &.visible {
        bottom: 50%;
    }

    & > #modal-footer {
        position: sticky;
        bottom: 0;
        padding: 12px 0 0;
        border-top: 1px solid var(--default-border);
        background: var(--default-bg);
    }

    &::-webkit-scrollbar { 
        display: none;
    }
`;

export {
    ModalWrapper
};
