import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useRedux';
import { hideToast } from '../../redux/action';
import cx from '../../utils/cx';
import { ToastWrapper } from './index.styled';

export type positionType = 'top-center' | 'top-left' | 'top-right' | 'bottom-center' | 'bottom-left' | 'bottom-right';
type statusType = 'success' | 'error' | 'warning' ;

interface IToastProps {
    children: any,
    status?: statusType | any,
    position: positionType,
    visibility: boolean
}

const Toast: React.FC<IToastProps> = (props) => {
    const { children, status, position, visibility } = props;
    // const [isShow, setShow] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // setShow(true);
        setTimeout(() => {
            // setShow(false);
            setTimeout(() => dispatch(hideToast()), 1000);
        }, 3000);
    }, [visibility]);

    return (
        <ToastWrapper className={cx({ visibility, status })} topCenter={position === 'top-center'} topLeft={position === 'top-left'} topRight={position === 'top-right'} bottomCenter={position === 'bottom-center'} bottomLeft={position === 'bottom-left'} bottomRight={position === 'bottom-right'}>
            {children}
        </ToastWrapper>
    );
};

Toast.defaultProps = {
    status: undefined
};

export default Toast;
