import styled from 'styled-components';

const IconWrapper = styled.div`
    position: relative;
    display: inline-flex;
    padding: 5px;
    width: auto;
    height: auto;
    border-radius: 100px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    &:hover {
        background: var(--default-fg-hover);
    }
`;

const IconItemWrapper = styled.i`
    position: relative;
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    &.feather {
        width: 16px;
        height: 16px;
    }
`;

export {
    IconWrapper,
    IconItemWrapper
};
