import styled from 'styled-components';

const TextAreaWrapper = styled.textarea`
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
    min-height: 42px;
    padding: 6px 1rem;
    border-radius: 10px;
    background: var(--default-bg);
    color: var(--default-fg);
    border: none;
    outline: none;
    resize: none;
`;

export { TextAreaWrapper };
