import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from '../../utils/cx';
import './styles.scss';

interface ITextProps {
    children: React.ReactNode | string,
    onClick?: any,
    link?: boolean,
    linkReplace?: boolean,
    to?: string,
    primary?: boolean,
    paragraph?: boolean,
    h1?: boolean,
    h2?: boolean,
    h3?: boolean,
    h4?: boolean,
    h5?: boolean,
    h6?: boolean,
    fontSmall?: boolean,
    fontExtraSmall?: boolean,
    fontLarge?: boolean,
    fontBold?: boolean,
    fontExtraBold?: boolean,
    style?: React.CSSProperties,
    className?: string
}

const Text: React.FC<ITextProps> = (props) => {
    const { children, onClick, link, linkReplace, to, primary, paragraph, h1, h2, h3, h4, h5, h6, fontSmall, fontExtraSmall, fontLarge, fontBold, fontExtraBold, style, className } = props;
    const navigate = useNavigate();
    const onClickLink = () => {
        navigate(to || '', { replace: linkReplace });
    };

    return (
        <>
            {!h1 && !h2 && !h3 && !h4 && !h5 && !h6 && !paragraph && (
                <span style={style} className={cx({ className, link, primary, fontSmall, fontExtraSmall, fontLarge, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</span>
            )}
            {!h1 && !h2 && !h3 && !h4 && !h5 && !h6 && paragraph && (
                <p style={style} className={cx({ className, link, primary, paragraph, fontSmall, fontExtraSmall, fontLarge, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</p>
            )}
            {h1 && (
                <h1 style={style} className={cx({ className, link, primary, h1, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</h1>
            )}
            {h2 && (
                <h2 style={style} className={cx({ className, link, primary, h2, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</h2>
            )}
            {h3 && (
                <h3 style={style} className={cx({ className, link, primary, h3, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</h3>
            )}
            {h4 && (
                <h4 style={style} className={cx({ className, link, primary, h4, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</h4>
            )}
            {h5 && (
                <h5 style={style} className={cx({ className, link, primary, h5, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</h5>
            )}
            {h6 && (
                <h6 style={style} className={cx({ className, link, primary, h6, fontBold, fontExtraBold })} onClick={link ? onClickLink : onClick}>{children}</h6>
            )}
        </>
    );
};

Text.defaultProps = {
    onClick: () => { },
    link: false,
    linkReplace: false,
    to: '/',
    primary: false,
    paragraph: false,
    h1: false,
    h2: false,
    h3: false,
    h4: false,
    h5: false,
    h6: false,
    fontSmall: false,
    fontExtraSmall: false,
    fontLarge: false,
    fontBold: false,
    fontExtraBold: false,
    className: '',
    style: undefined
};

export default Text;
