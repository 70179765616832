import styled from 'styled-components';
import './styles.scss';

const LoadingWrapper = styled.div`
    position: fixed;
    display: flex;
    width: 120px;
    height: 120px;
    align-items: center;
    justify-content: center;
    background: var(--default-bg);
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 102;
`;

const LoadingAnimation = styled.div`
    position: relative;
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;

    &::before,
    &::after {
        border-radius: 50%;
        content: "";
        display: block;
        height: 20px;
        width: 20px;
    }
    &::before {
        animation: ball1 1s infinite;
        background-color: var(--default-fg);
        box-shadow: 30px 0 0 var(--default-primary);
        margin-bottom: 10px;
    }

    &::after {
        animation: ball2 1s infinite;
        background-color: var(--default-primary);
        box-shadow: 30px 0 0 var(--default-fg);
    }
`;

export {
    LoadingWrapper,
    LoadingAnimation
};
