import { useState, useEffect } from 'react';
import API from '../configs/api';
import { hideLoading, showLoading } from '../redux/action';
import { useAppDispatch } from './useRedux';

interface ITransactionQuery {
    currentPage?: number,
    limit?: number,
    status?: string,
    category?: string,
    whereIn?: string,
    search?: string,
    start_date?: string,
    end_date?: string
}

const useTransaction = (query: ITransactionQuery) => {
    const { currentPage, limit, status, category, search, start_date, end_date, whereIn } = query;
    const dispatch = useAppDispatch();
    const [list, setList] = useState<any>([]);
    const [totalList, setTotalList] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        let type = '';
        dispatch(showLoading());
        setLoading(true);

        if (!status) type = 'pending';
        else if (status === 'all') type = '';
        else type = status;

        const payload = {
            query: {
                search,
                currentPage,
                limit: limit || 10,
                status: type,
                category: category || '',
                start_date: start_date || '',
                end_date: end_date || '',
                whereIn: whereIn || ''
            }
        };

        API.getTransaction(payload).then((result: any) => {
            if (result.data.total) {
                setTotalList(result.data.total);
            }
            setList(result.data.list);
            setLoading(false);
            dispatch(hideLoading());
        }).catch(() => {
            setList([]);
            setTotalList(0);
            setLoading(false);
            dispatch(hideLoading());
        });
    };

    useEffect(() => {
        fetchData();

        return () => {
            setList(list);
            setTotalList(totalList);
            setLoading(false);
        };
    }, [currentPage, status, category, search, whereIn]);

    return { list, totalList, loading } as const;
};

export default useTransaction;
