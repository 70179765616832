import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import CheckBox from '../../../components/CheckBox';
import InputText from '../../../components/InputText';
import SelectInput from '../../../components/SelectInput';
import Text from '../../../components/Text';
import API from '../../../configs/api';
import useCampaign from '../../../hooks/useCampaign';
import useCategory from '../../../hooks/useCategory';
import { useAppDispatch } from '../../../hooks/useRedux';
import Admin from '../../../layouts/Admin';
import { hideLoading, showLoading, showToast } from '../../../redux/action';

const statusOpt = [
    { title: 'ACTIVE', value: 'ACTIVE' },
    { title: 'INACTIVE', value: 'INACTIVE' }
];

const EditProduct: React.FC = () => {
    const { product_id } = useParams();
    const navigate = useNavigate();
    const category = useCategory(1, 1000);
    const campaign = useCampaign(1, 1000);
    const dispatch = useAppDispatch();
    const [name, setName] = useState('');
    const [model, setModel] = useState('');
    const [categoryOpt, setCategoryOpt] = useState<any[]>([]);
    const [categorySelected, setCategorySelected] = useState<any>({});
    const [campaignSelected, setCampaignSelected] = useState<any[]>([]);
    const [status, setStatus] = useState<any>({});

    useEffect(() => {
        if (category.list.length < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < category.list.length; i++) {
            const obj = {
                title: category.list[i]?.name,
                value: category.list[i]?.id
            };
            setCategoryOpt((e: any) => [...e, obj]);
        }
    }, [category.list, category.loading]);

    const handleSubmit = () => {
        dispatch(showLoading());
        const payload = {
            params: `/${product_id}`,
            body: {
                name,
                model,
                category_id: categorySelected.value,
                campaigns: campaignSelected,
                status: campaignSelected.length > 0 ? status.value : 'INACTIVE'
            }
        };
        API.updateProduct(payload).then((result: any) => {
            dispatch(showToast(result.meta.message, 'success'));
            setTimeout(() => {
                dispatch(hideLoading());
                navigate(0);
            }, 1000);
        }).catch(() => {
            dispatch(hideLoading());
        });
    };

    const handleSelectCampaign = (id: number) => {
        const existingCampaign = campaignSelected.filter((i: any) => i.campaign_id === id);
        if (existingCampaign.length > 0) {
            const newCampaign = campaignSelected.filter((i: any) => i.campaign_id !== id);
            setCampaignSelected(newCampaign);
            return;
        }
        setCampaignSelected((e) => [...e, { campaign_id: id }]);
    };

    const checkedCampaign = (id: number): boolean => {
        const existingCampaign = campaignSelected.filter((i: any) => i.campaign_id === id);
        if (existingCampaign.length > 0) return true;
        return false;
    };

    useEffect(() => {
        const payload = {
            params: `/${product_id}`
        };
        API.getProduct(payload).then((result: any) => {
            setName(result.data.name);
            setModel(result.data.model);
            setStatus({
                title: result.data.status,
                value: result.data.status
            });
            setCategorySelected({
                title: result.data.category_name,
                value: result.data.category_id
            });
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < result.data.product_campaigns.length; i++) {
                const item = result.data.product_campaigns[i];
                handleSelectCampaign(item.id);
            }
        });
    }, []);

    return (
        <Admin activeMenu="new-product">
            <div className="row">
                <div className="col-7">
                    <Card border>
                        <InputText
                            block
                            border
                            label="Nama"
                            value={name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        />
                        {categoryOpt.length > 0 ? (
                            <SelectInput
                                label="Type / Category"
                                options={categoryOpt}
                                optionHeader
                                headerPlaceholder="Category ..."
                                placeholder="Select Category / Type"
                                value={categorySelected.title}
                                onOptionSelected={(v: any) => setCategorySelected(v)}
                            />
                        ) : (
                            <InputText
                                block
                                border
                                label="Type / Category"
                            />
                        )}
                        <InputText
                            block
                            border
                            label="Model"
                            value={model}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModel(e.target.value)}
                        />
                        <SelectInput
                            label="Status Produk"
                            options={statusOpt}
                            value={status.title}
                            onOptionSelected={(v: any) => setStatus(v)}
                        />
                    </Card>
                </div>
                <div className="col-5">
                    <Card border>
                        <Text paragraph fontBold>Campaign</Text>
                        {campaignSelected && campaign.list.length > 0 && campaign.list.map((i: any) => (
                            <CheckBox
                                key={i.id}
                                label={i.name}
                                checked={checkedCampaign(i.id)}
                                onClick={() => {
                                    handleSelectCampaign(i.id);
                                }}
                            />
                        ))}
                    </Card>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-8" />
                <div className="col-2">
                    <Button block fullWidth borderPrimary>Cancel</Button>
                </div>
                <div className="col-2">
                    <Button block fullWidth primary onClick={() => handleSubmit()}>Edit</Button>
                </div>
            </div>
        </Admin>
    );
};

export default EditProduct;
