import { setTheme, setProgress, setToast, setLoading, setSidebar } from '../../reducers/ui';

const setThemeLight = () => (dispatch: any) => {
    localStorage.setItem('theme', 'light');
    dispatch(setTheme('light'));
};

const setThemeDark = () => (dispatch: any) => {
    localStorage.setItem('theme', 'dark');
    dispatch(setTheme('dark'));
};

const showProgress = () => (dispatch: any) => {
    dispatch(setProgress(true));
};

const showToast = (message: string, status: string) => (dispatch: any) => {
    dispatch(setToast({ visible: true, message, status }));
};

const hideToast = () => (dispatch: any) => {
    dispatch(setToast({ visible: false, message: '', status: '' }));
};

const showLoading = () => (dispatch: any) => {
    dispatch(setLoading(true));
};

const hideLoading = () => (dispatch: any) => {
    dispatch(setLoading(false));
};

const openSidebar = () => (dispatch: any) => {
    dispatch(setSidebar(true));
};

const closeSidebar = () => (dispatch: any) => {
    dispatch(setSidebar(false));
};

export {
    setThemeLight,
    setThemeDark,
    showProgress,
    showToast,
    hideToast,
    showLoading,
    hideLoading,
    openSidebar,
    closeSidebar
};
