import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initTheme = localStorage.getItem('theme') || '';

interface IState {
    theme: string,
    progress: boolean,
    toastVisible: boolean,
    toastStatus: string,
    toastMessage: string,
    loading: boolean,
    sidebar: boolean
}

const initialState: IState = {
    theme: initTheme || 'light',
    progress: false,
    toastVisible: false,
    toastStatus: 'success',
    toastMessage: '',
    loading: false,
    sidebar: true
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;
        },
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.progress = action.payload;
        },
        setToast: (state, action: PayloadAction<any>) => {
            state.toastVisible = action.payload.visible;
            state.toastStatus = action.payload.status;
            state.toastMessage = action.payload.message;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setSidebar: (state, action: PayloadAction<boolean>) => {
            state.sidebar = action.payload;
        }
    }
});

export const { setTheme, setProgress, setToast, setLoading, setSidebar } = uiSlice.actions;

export default uiSlice.reducer;
