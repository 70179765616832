import CONSTANT from '../../../constant';
import { setProfile, setToken } from '../../reducers/auth';
import { showLoading } from '../ui';

const login = (data: any, token: string) => (dispatch: any) => {
    localStorage.setItem('user-data', JSON.stringify(data));
    localStorage.setItem(CONSTANT.APP.SESSION_KEY, token);
    dispatch(setProfile(data));
    dispatch(setToken(token));
};

const logout = () => (dispatch: any) => {
    dispatch(showLoading());
    localStorage.clear();
    setTimeout(() => {
        window.location.href = '/login';
    }, 2000);
};

export {
    login,
    logout
};
