import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import qr from 'qrcode';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import Modal from '../../../components/Modal';
import API from '../../../configs/api';
import { useAppDispatch } from '../../../hooks/useRedux';
import { showLoading, showToast } from '../../../redux/action';

interface IAddQrDialogProps {
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const AddQrDialog: React.FC<IAddQrDialogProps> = (props) => {
    const { dialogHandler } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [link, setLink] = useState('');

    const generateQr = async (data: string) => {
        const qrstring = await qr.toDataURL(data, { version: 4 });
        return qrstring;
    };

    const onSubmit = async () => {
        dispatch(showLoading());
        const payload = {
            body: {
                name,
                image: await generateQr(link),
                link
            }
        };
        API.createQr(payload).then((result: any) => {
            dispatch(showToast(result.meta.message, 'success'));
            setTimeout(() => {
                navigate(0);
            }, 1000);
        });
    };

    return (
        <Modal title="Add QR" visibility modalHandler={dialogHandler}>
            <InputText
                block
                border
                label="Name"
                type="text"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
            <InputText
                block
                border
                label="Link"
                type="text"
                value={link}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLink(e.target.value)}
            />
            <div className="row mt-3">
                <div className="col-6">
                    <Button block fullWidth borderPrimary onClick={() => dialogHandler(false)}>Cancel</Button>
                </div>
                <div className="col-6">
                    <Button block fullWidth primary onClick={() => onSubmit()}>Add</Button>
                </div>
            </div>
        </Modal>
    );
};

export default AddQrDialog;
