import styled from 'styled-components';
import { InputTextWrapper } from '../InputText/index.styled';

const SelectInputLabel = styled.label<{bold?: boolean, extraBold?: boolean}>`
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
    cursor: pointer;
`;

const SelectWrapper = styled.div`
    position: relative;
    display: block;
    height: auto;
    width: 100%;
    margin: 0;

    #placeholder {
        color: #A5A5A6;
    }
    
    +label {
        margin-top: 10px;
    }
`;

const SelectInputWrapper = styled.div`
    position: relative;
    display: flex;
    height: 42px;
    width: 100%;
    align-items: center;
    background: var(--default-bg);
    padding: 6px 1rem;
    margin: 0;
    border-radius: 10px;
    outline: none;
    border: 1px solid #D8CFCD;
    cursor: pointer;
    #toggle-icon {
        position: absolute;
        width: auto;
        height: auto;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }
`;

const OptionWrapper = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    max-height: 240px;
    padding: 10px 20px 0;
    background: var(--default-bg);
    border-radius: 10px;
    box-shadow: 0px 3.12956px 12.5182px rgba(146, 146, 146, 0.25);
    top: 55px;
    left: 0;
    user-select: none;
    overflow: auto;
    z-index: 100;
`;

const OptionHeader = styled.div`
    position: sticky;
    display: flex;
    width: center;
    flex-direction: row;
    background: var(--default-bg);
    align-items: center;
    padding: 20px 0;
    margin: 0 0 10px;
    top: 0;
    border-bottom: 1px dashed  var(--default-border);
    z-index: 1;
    #icon {
        position: relative;
        display: inline-block;
        width: auto;
        margin-right: 10px;
        img {
            width: 100%;
        }
    }
    ${InputTextWrapper} {
        width: 100%;
    }
`;

const OptionItem = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px 0 15px;
    margin: 0 0 10px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--default-border);
    cursor: pointer;
    #icon {
        position: relative;
        display: inline-block;
        width: auto;
        margin-right: 10px;
        img {
            width: 100%;
        }
    }
    #desc {
        position: relative;
        display: block;
        color: var(--default-fg);
        width: 100%;
    }
    span {
        color: #A5A5A6;
    }
    &:last-child {
        border: none;
    }
    &:hover {
        background: rgb(250, 250, 250);
    }
`;

export { SelectWrapper, SelectInputLabel, SelectInputWrapper, OptionWrapper, OptionHeader, OptionItem };
