import React, { useEffect, useState } from 'react';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Table from '../../components/Table';
import useDate from '../../helpers/useDate';
import useCampaign from '../../hooks/useCampaign';
import Admin from '../../layouts/Admin';
import AddCampaignDialog from './components/AddCampaignDialog';

const Campaign: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const { list, totalList, loading } = useCampaign(currentPage, 10);

    const [addCampaignDialog, setAddCampaignDialog] = useState(false);

    const [totalRow, setTotalRow] = useState(0);

    useEffect(() => {
        if (totalList > 0) {
            setTotalRow(totalList);
        }
    }, [list]);

    const columns: any[] = [
        {
            name: 'Name',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.name
        },
        {
            name: 'Campaign Code',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.campaign_code
        },
        {
            name: 'Type / Category',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.category_name
        },
        {
            name: 'Start Date',
            sortable: true,
            minWidth: '80px',
            selector: (row: any) => useDate.friendlyDate(row.start_date).toString().split(' ')[0]
        },
        {
            name: 'End Date',
            sortable: true,
            minWidth: '80px',
            selector: (row: any) => useDate.friendlyDate(row.end_date).toString().split(' ')[0]
        },
        {
            name: 'Status',
            sortable: true,
            width: '100px',
            selector: (row: any) => (row.status === 'ACTIVE' ? <Badge status="success">{row.status}</Badge> : <Badge status="error">{row.status}</Badge>)
        },
        {
            allowOverflow: true,
            cell: (row: any) => <Button borderPrimary link to={`/admin/campaign/detail/${row.id}`} className="btn-small">VIEW</Button>,
            style: {
                justifyContent: 'flex-end'
            }
        }
    ];

    return (
        <Admin activeMenu="campaign">
            <div className="row mb-3">
                <div className="col-6" />
                <div className="col-6 text-end">
                    <Button primary onClick={() => setAddCampaignDialog(true)}>+ Add</Button>
                </div>
            </div>
            <Card border>
                <Table
                    data={list}
                    columns={columns}
                    loading={loading}
                    paginate
                    currentPage={currentPage}
                    totalList={totalRow}
                    onPageChange={(e) => {
                        setCurrentPage(e.selected + 1);
                    }}
                />
            </Card>
            {addCampaignDialog && (
                <AddCampaignDialog dialogHandler={(visible: boolean) => setAddCampaignDialog(visible)} />
            )}
        </Admin>
    );
};

export default Campaign;
