import { useState, useEffect } from 'react';
import API from '../configs/api';
import { hideLoading, showLoading } from '../redux/action';
import { useAppDispatch } from './useRedux';

const useQr = (currentPage?: number, limit?: number) => {
    const dispatch = useAppDispatch();
    const [list, setList] = useState<any>([]);
    const [totalList, setTotalList] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        dispatch(showLoading());
        setLoading(true);

        const payload = {
            query: { currentPage, limit: limit || 10 }
        };

        API.getQr(payload).then((result: any) => {
            if (result.data.total) {
                setTotalList(result.data.total);
            }
            console.log(result);
            setList(result.data.list);
            setLoading(false);
            dispatch(hideLoading());
        }).catch(() => {
            setList([]);
            setTotalList(0);
            setLoading(false);
            dispatch(hideLoading());
        });
    };

    useEffect(() => {
        fetchData();

        return () => {
            setList(list);
            setTotalList(totalList);
            setLoading(false);
        };
    }, [currentPage]);

    return { list, totalList, loading } as const;
};

export default useQr;
