const programGopaySmartphone = {
    name: true,
    name_label: 'Nama',
    gender: true,
    gender_label: 'Jenis Kelamin',
    phone: true,
    phone_label: 'Nomor Telpon',
    serial_or_imei: true,
    serial_or_imei_label: 'Nomor IMEI Smartphone',
    serial_image: true,
    serial_image_label: 'Screenshoot IMEI Smartphone',
    identity_number: false,
    identity_number_label: '',
    identity_image: false,
    identity_image_label: '',
    model: false,
    model_label: '',
    receipt: true,
    receipt_label: 'Foto Invoice'
};

export default programGopaySmartphone;
