import classNames from 'classnames';
import React, { useEffect } from 'react';
import featherIcon from 'feather-icons';
import { useNavigate } from 'react-router-dom';
import { IconItemWrapper, IconWrapper } from './index.styled';
import './style.dark.scss';
import './style.light.scss';

interface IIconProps {
    name: string,
    onClick?: any,
    invert?: boolean,
    link?: boolean,
    linkReplace?: boolean,
    to?: string,
    className?: string,
    feather?: boolean
}

const Icon: React.FC<IIconProps> = (props) => {
    const { name, onClick, invert, link, linkReplace, to, className, feather } = props;
    const navigate = useNavigate();

    const cx = classNames(className, {
        invert,
        'is-link': link,
        'icon-moon': name === 'moon',
        'icon-sun': name === 'sun',
        'icon-chart': name === 'chart',
        'icon-wallet': name === 'wallet',
        'icon-paper': name === 'paper',
        'icon-graph': name === 'graph',
        'icon-three-user': name === 'multiuser',
        'icon-profile': name === 'profile',
        'icon-logout': name === 'logout',
        'icon-discount': name === 'discount',
        'icon-download': name === 'download',
        'icon-image': name === 'image',
        'icon-location': name === 'location',
        'icon-scan': name === 'scan',
        'icon-swap': name === 'swap',
        'icon-swap-2': name === 'swap-2',
        'icon-ticket': name === 'ticket',
        'icon-x': name === 'x',
        'icon-trash': name === 'trash',
        'icon-search': name === 'search',
        'icon-tick': name === 'tick',
        'icon-eye': name === 'eye',
        'icon-paper-plus': name === 'paper-plus',
        'icon-folder': name === 'folder',
        'icon-edit': name === 'edit',
        'icon-qr-code': name === 'qr',
        'icon-password': name === 'password'
    });

    const onClickLink = () => {
        navigate(to || '', { replace: linkReplace });
    };

    useEffect(() => {
        if (feather) {
            featherIcon.replace();
        }
    }, []);

    return (
        <>
            {onClick && (
                <IconWrapper onClick={link ? onClickLink : onClick}>
                    {feather && (
                        <IconItemWrapper data-feather={name} className={cx} />
                    )}
                    {!feather && (
                        <IconItemWrapper className={cx} />
                    )}
                </IconWrapper>
            )}
            {!onClick && (
                <>
                    {feather && (
                        <IconItemWrapper data-feather={name} className={cx} onClick={link ? onClickLink : onClick} />
                    )}
                    {!feather && (
                        <IconItemWrapper className={cx} onClick={link ? onClickLink : onClick} />
                    )}
                </>
            )}
        </>
    );
};

Icon.defaultProps = {
    onClick: undefined,
    invert: false,
    link: false,
    linkReplace: false,
    to: '',
    className: '',
    feather: false
};

export default Icon;
