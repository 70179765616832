import React from 'react';
import cx from '../../utils/cx';
import Icon from '../Icon';
import { InputIconWrapper, InputTextGroup, InputTextLabel, InputTextWrapper } from './index.styled';

interface IInputTextProps {
    label?: string,
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined,
    placeholder?: string,
    type?: React.HTMLInputTypeAttribute,
    value?: any,
    className?: string,
    style?: React.CSSProperties,
    iconLeft?: string,
    iconLeftOnClick?: any,
    iconRight?: string,
    iconRightOnClick?: any,
    block?: boolean,
    border?: boolean,
    borderHover?: boolean,
    rounded?: boolean,
    fontSmall?: boolean,
    fontExtraSmall?: boolean,
    disabled?: boolean
}

const InputText: React.FC<IInputTextProps> = (props) => {
    const { label, onChange, placeholder, type, value, className, style, iconLeft, iconLeftOnClick, iconRight, iconRightOnClick, block, border, borderHover, rounded, fontSmall, fontExtraSmall, disabled } = props;
    return (
        <>
            {label && (
                <InputTextLabel className={cx({ className: 'fw-bold', fontSmall, fontExtraSmall })}>{label}</InputTextLabel>
            )}
            {iconRight || iconLeft ? (
                <InputTextGroup>
                    {iconLeft && (
                        <InputIconWrapper id="left">
                            <Icon name={iconLeft} onClick={iconLeftOnClick} />
                        </InputIconWrapper>
                    )}
                    <InputTextWrapper disabled={disabled} onChange={onChange} placeholder={placeholder} type={type} value={value} style={style} className={cx({ className, block, border, borderHover, rounded, fontSmall, fontExtraSmall })} />
                    {iconRight && (
                        <InputIconWrapper id="right">
                            <Icon name={iconRight} onClick={iconRightOnClick} />
                        </InputIconWrapper>
                    )}
                </InputTextGroup>
            ) : null}
            {!iconLeft && !iconRight ? (
                <InputTextWrapper disabled={disabled} onChange={onChange} placeholder={placeholder} type={type} value={value} style={style} className={cx({ className, block, border, borderHover, rounded, fontSmall, fontExtraSmall })} />
            ) : null}
        </>
    );
};

InputText.defaultProps = {
    label: '',
    onChange: () => { },
    placeholder: '',
    type: 'text',
    value: undefined,
    className: '',
    style: undefined,
    iconLeft: undefined,
    iconLeftOnClick: undefined,
    iconRight: undefined,
    iconRightOnClick: undefined,
    block: false,
    border: false,
    borderHover: false,
    rounded: false,
    fontSmall: false,
    fontExtraSmall: false,
    disabled: false
};

export default InputText;
