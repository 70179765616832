import React from 'react';
import styled from 'styled-components';
import Text from '../../components/Text';

const NotFoundWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NotFound: React.FC = () => {
    return (
        <NotFoundWrapper>
            <Text h5>404</Text>
            <Text h6>Page Not Found</Text>
        </NotFoundWrapper>
    );
};

export default NotFound;
