import React from 'react';
import cx from '../../utils/cx';
import { TextAreaWrapper } from './index.styled';

interface ITextAreaProps {
    rows?: number,
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined,
    className?: string,
    value?: any,
    style?: React.CSSProperties,
    block?: boolean,
    border?: boolean,
    borderHover?: boolean,
    rounded?: boolean,
    fontSmall?: boolean,
    fontExtraSmall?: boolean,
    disabled?: boolean
}

const TextArea: React.FC<ITextAreaProps> = (props) => {
    const { rows, onChange, value, className, style, block, border, borderHover, rounded, fontSmall, fontExtraSmall, disabled } = props;
    return (
        <TextAreaWrapper rows={rows} disabled={disabled} onChange={onChange} style={style} className={cx({ className, block, border, borderHover, rounded, fontSmall, fontExtraSmall })}>
            {value}
        </TextAreaWrapper>
    );
};

TextArea.defaultProps = {
    rows: 4,
    value: '',
    onChange: () => { },
    className: '',
    style: undefined,
    block: false,
    border: false,
    borderHover: false,
    rounded: false,
    fontSmall: false,
    fontExtraSmall: false,
    disabled: false
};

export default TextArea;
