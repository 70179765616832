import React, { useState } from 'react';
import styled from 'styled-components';
// import qr from 'qrcode';
import Admin from '../../layouts/Admin';
import Image from '../../components/Image';
import Card from '../../components/Card';
import Button from '../../components/Button';
import AddQrDialog from './components/AddQrDialog';
import useQr from '../../hooks/useQr';
import Text from '../../components/Text';

const QRWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
    }
`;

const QRCode: React.FC = () => {
    const { list, loading } = useQr();
    const [addQrDialog, setAddQrDialog] = useState(false);

    // const generateQr = async (data: string) => {
    //     const code = await qr.toDataURL(data, { version: 4 });
    //     console.log(code);
    //     return code;
    // };

    const downloadQr = (name: string, data: string) => {
        const a = document.createElement('a');
        a.href = data;
        a.download = `${name}.png`;
        a.click();
    };

    return (
        <Admin activeMenu="qr-code">
            <div className="row">
                <div className="col-6" />
                <div className="col-4" />
                <div className="col-2">
                    <Button primary block fullWidth onClick={() => setAddQrDialog(true)}>+ Add</Button>
                </div>
                {!loading && list.map((i: any) => (
                    <div className="col-3" key={i.id}>
                        <Card shadow>
                            <QRWrapper>
                                <Image src={i.image} />
                            </QRWrapper>
                            <Text className="text-center my-2" paragraph>{i.name}</Text>
                            <Button block fullWidth primary onClick={() => downloadQr(i.name, i.image)}>Download</Button>
                        </Card>
                    </div>
                ))}
            </div>
            {addQrDialog && (
                <AddQrDialog dialogHandler={(visible: boolean) => setAddQrDialog(visible)} />
            )}
        </Admin>
    );
};

export default QRCode;
