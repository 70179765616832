import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import InputText from '../../components/InputText';
import Auth from '../../layouts/Auth';
import Image from '../../components/Image';
import logo from '../../assets/img/logo-sharp-red.png';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { hideLoading, login, showLoading, showToast } from '../../redux/action';
import API from '../../configs/api';

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loggedIn } = useAppSelector((state) => state.authReducer);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);

    const handleSubmit = () => {
        if (!email || !password) {
            return;
        }
        dispatch(showLoading());

        const payload = {
            body: { email, password }
        };

        API.loginAdmin(payload).then((result: any) => {
            dispatch(showToast('Login success', 'success'));
            setTimeout(() => {
                dispatch(hideLoading());
                dispatch(login(result.data, result.token));
            }, 1000);
        }).catch(() => {
            dispatch(showToast('Invalid password combination', 'error'));
            dispatch(hideLoading());
        });
    };

    useEffect(() => {
        if (loggedIn) {
            navigate('/admin', { replace: true });
        }
    }, [loggedIn]);

    return (
        <Auth>
            <div id="logo">
                <Image src={logo} />
            </div>
            <div className="container">
                <InputText
                    block
                    border
                    fontSmall
                    label="Email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
                <InputText
                    block
                    border
                    fontSmall
                    type={passwordShow ? 'text' : 'password'}
                    label="Password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    iconRight="eye"
                    iconRightOnClick={() => {
                        setPasswordShow(!passwordShow);
                    }}
                />
                <div className="mt-4">
                    <Button block fullWidth primary onClick={() => handleSubmit()}>Login</Button>
                </div>
            </div>
        </Auth>
    );
};

export default Login;
