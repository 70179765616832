import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import Image from '../../../components/Image';
import Main from '../../../layouts/Main';
import logo from '../../../assets/img/logo-sharp-red.png';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import SelectInput from '../../../components/SelectInput';
import './index.scss';
import useProduct from '../../../hooks/useProduct';
import API from '../../../configs/api';
import { useAppDispatch } from '../../../hooks/useRedux';
import { hideLoading, showLoading, showToast } from '../../../redux/action';
import Text from '../../../components/Text';

// const genderOpt = [
//     { id: 0, title: 'Laki-laki', value: 'LAKI-LAKI' },
//     { id: 1, title: 'Perempuan', value: 'PEREMPUAN' }
// ];

const AudioForm: React.FC = () => {
    const category_id = 3;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const products = useProduct({ currentPage: 1, limit: 1000, category_id, status: 'ACTIVE' });
    const [modelOpt, setModelOpt] = useState<any>([]);
    const [city, setCity] = useState<any>([]);
    const [name, setName] = useState('');
    const [dateOfSell, setDateOfSell] = useState<Date | string>(new Date());
    // const [gender, setGender] = useState<any>({});
    const [phone, setPhone] = useState('');
    const [model, setModel] = useState<any>({});
    const [serialNumber, setSerialNumber] = useState('');
    const [serialImage, setSerialImage] = useState<any>({});
    const [guaranteeImage, setGuaranteeImage] = useState<any>({});
    // const [identity, setIdentity] = useState('');
    const [receipt, setReceipt] = useState<any>({});
    const [store, setStore] = useState('');

    useEffect(() => {
        // const opt = [];
        if (products.list < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < products.list.length; i++) {
            const obj = {
                title: products.list[i]?.model,
                value: products.list[i]?.model,
                product_id: products.list[i]?.id
            };
            setModelOpt((e: any) => [...e, obj]);
        }
    }, [products.list, products.loading]);

    const checkForm = (): boolean => {
        if (!name) {
            dispatch(showToast('Nama belum diisi', 'warning'));
            return true;
        }
        // if (!gender.value) {
        //     dispatch(showToast('Pilih jenis kelamin', 'warning'));
        //     return true;
        // }
        if (!model.product_id) {
            dispatch(showToast('Pilih model smartpone', 'warning'));
            return true;
        }
        if (!phone) {
            dispatch(showToast('No HP belum diisi', 'warning'));
            return true;
        }
        if (phone.length < 8) {
            dispatch(showToast('Masukkan No HP yang valid', 'warning'));
            return true;
        }
        // if (!ewalletNumber) {
        //     dispatch(showToast('No Ewallet belum diisi', 'warning'));
        //     return true;
        // }
        // if (ewalletNumber.length < 8) {
        //     dispatch(showToast('Masukkan No Ewallet yang valid', 'warning'));
        //     return true;
        // }
        // if (!identity) {
        //     dispatch(showToast('NIK belum diisi', 'warning'));
        //     return true;
        // }
        if (!serialNumber) {
            dispatch(showToast('Serial Number belum diisi', 'warning'));
            return true;
        }
        if (!guaranteeImage.name) {
            dispatch(showToast('Upload foto Kartu Garansi', 'warning'));
            return true;
        }
        if (!serialImage.name) {
            dispatch(showToast('Upload foto Serial Number', 'warning'));
            return true;
        }
        if (!receipt.name) {
            dispatch(showToast('Upload foto Invoice', 'warning'));
            return true;
        }
        if (!store) {
            dispatch(showToast('Ketik nama toko', 'warning'));
            return true;
        }
        return false;
    };

    const onSubmit = () => {
        if (checkForm()) {
            return;
        }
        dispatch(showLoading());

        const formData = new FormData();
        formData.append('name', name);
        // formData.append('gender', gender.value);
        formData.append('phone', phone.split(' ').join(''));
        formData.append('ewallet_number', String('0800000000000').split(' ').join(''));
        formData.append('category_id', category_id.toString());
        formData.append('product_id', model.product_id);
        formData.append('model', model.value);
        formData.append('serial_number', serialNumber);
        // formData.append('identity_number', identity);
        formData.append('guarantee', guaranteeImage);
        formData.append('serial_number_image', serialImage);
        formData.append('receipt', receipt);
        formData.append('campaigns', JSON.stringify([{ campaign_id: 25 }]));
        formData.append('store', store);
        formData.append('city', city);
        formData.append('date', moment(dateOfSell).format('YYYY-MM-DD'));

        const payload = {
            params: '/audio',
            content: 'form-data',
            body: formData
        };

        API.createTransaction(payload).then(() => {
            dispatch(showToast('Data berhasil disubmit', 'success'));
            setTimeout(() => {
                dispatch(hideLoading());
                navigate(0);
            }, 1000);
        }).catch((err: any) => {
            dispatch(showToast(err.meta.message, 'warning'));
            dispatch(hideLoading());
        });
    };

    return (
        <Main>
            <div className="logo">
                <Image src={logo} />
            </div>
            <Card shadow>
                <InputText
                    block
                    border
                    label="Nama Toko Pembelian"
                    value={store}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStore(e.target.value)}
                />
                <InputText
                    block
                    border
                    label="Kota / Kabupaten"
                    value={city}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
                />
                <InputText
                    block
                    border
                    label="Nama Lengkap"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                />
                <InputText
                    block
                    border
                    label="Tanggal Pembelian"
                    type="date"
                    value={dateOfSell}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDateOfSell(e.target.value);
                    }}
                />
                {/* <SelectInput
                    label="Jenis Kelamin"
                    options={genderOpt}
                    value={gender.title}
                    onOptionSelected={(value: any) => setGender(value)}
                /> */}
                {modelOpt.length > 0 ? (
                    <SelectInput
                        label="Model"
                        options={modelOpt}
                        value={model.title}
                        optionHeader
                        headerPlaceholder="Ketik model"
                        onOptionSelected={(value: any) => {
                            setModel(value);
                        }}
                    />
                ) : (
                    <InputText border placeholder="Model" />
                )}
                <InputText
                    block
                    border
                    label="Nomor WhatsApp"
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                />
                {/* <InputText
                    block
                    border
                    className=""
                    label="Nomor Ewallet (Gopay)"
                    value={ewalletNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEwalletNumber(e.target.value)}
                /> */}
                {/* <InputText
                    block
                    border
                    label="Nomor KTP (NIK)"
                    value={identity}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIdentity(e.target.value)}
                /> */}
                <InputText
                    block
                    border
                    label="Nomor Seri Audio"
                    value={serialNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSerialNumber(e.target.value)}
                />
                <InputText
                    block
                    border
                    label="Foto Nomor Seri (di belakang unit)"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.files) {
                            return;
                        }
                        const file = e.target.files[0];
                        setSerialImage(file);
                    }}
                />
                <InputText
                    block
                    border
                    label="Foto Kartu Garansi"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.files) {
                            return;
                        }
                        const file = e.target.files[0];
                        setGuaranteeImage(file);
                    }}
                />
                <InputText
                    block
                    border
                    label="Foto Invoice"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.files) {
                            return;
                        }
                        const file = e.target.files[0];
                        setReceipt(file);
                    }}
                />
                <Text paragraph fontSmall>*Untuk Pembelian Credit Bisa Diganti dengan Tanda Terima Barang.</Text>
                <Button block fullWidth primary className="mt-3 animate-rounded" onClick={() => onSubmit()}>SUBMIT</Button>
            </Card>
        </Main>
    );
};

export default AudioForm;
