import styled from 'styled-components';

const ButtonWrapper = styled.button<{ shadow?: boolean }>`
    position: relative;
    display: inline-block;
    width: auto;
    background: transparent;
    color: var(--default-fg);
    min-height: 42px;
    border-radius: 10px;
    padding: 0 15px;
    margin: 0 2px;
    border: none;
    outline: none;
    ${(props) => (props.shadow ? 'box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;' : '')}

    &.btn-small {
        min-height: 30px;
        border-radius: 6px;
    }
`;

export {
    ButtonWrapper
};
