import React from 'react';
import cx from '../../utils/cx';
import { LoadingAnimation, LoadingWrapper } from './index.styled';

interface ILoadingProps {
    visibility: boolean
}

const Loading: React.FC<ILoadingProps> = (props) => {
    const { visibility } = props;
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {visibility && (
                <>
                    <div id="overlay" className={cx({ visibility })} />
                    <LoadingWrapper>
                        <LoadingAnimation />
                    </LoadingWrapper>
                </>
            )}
        </>
    );
};

export default Loading;
