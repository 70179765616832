import styled from 'styled-components';

const AdminWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    max-width: calc(100% - 20%);
    padding: 0 2rem 0 1rem;
    background: var(--default-canvas);
    float: right;
    transition: max-width .3s ease;

    &.sidebar-minimize {
        max-width: calc(100% - 100px);
    }

    @media only screen and (max-width: 768px) {
        max-width: 100%;
        padding: 0 1rem;

        &.sidebar-minimize {
            max-width: 100%;
        }
    }
`;

const AdminContent = styled.div`
    position: relative;
    display: block;
    width: 100%;
    margin-top: 80px;
    padding-bottom: 30px;

    &.has-tab {
        margin-top: 56px;
    }
`;

export {
    AdminWrapper,
    AdminContent
};
