/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import cx from '../../utils/cx';
import Icon from '../Icon';
import InputText from '../InputText';
import { OptionHeader, OptionItem, OptionWrapper, SelectInputLabel, SelectInputWrapper, SelectWrapper } from './index.styled';

interface ISelectInput {
    options: any[],
    label?: string,
    value?: string,
    placeholder?: string,
    optionHeader?: boolean,
    headerPlaceholder?: string,
    optionIcon?: any,
    className?: any,
    style?: React.CSSProperties,
    // eslint-disable-next-line no-unused-vars
    onOptionSelected?: (value: string) => void
}

const SelectInput: React.FC<ISelectInput> = (props) => {
    const { options, label, style, className, optionHeader, headerPlaceholder, optionIcon, value, placeholder, onOptionSelected } = props;
    const [data, setData] = useState<any>([]);
    const [isOpen, setOpen] = useState(false);
    const [filterName, setFilterName] = useState('');

    const handleOptionSelect = (selectedItem: any) => {
        if (onOptionSelected) {
            onOptionSelected(selectedItem);
        }
        setOpen(false);
    };

    useEffect(() => {
        if (filterName.length > 0) {
            const matches = options.filter((match: any) => {
                const regex = new RegExp(`${filterName}`, 'gi');
                return match.title.match(regex);
            });
            setData(matches);
            return;
        }
        setData(options);
    }, [filterName]);

    return (
        <>
            {label && (
                <SelectInputLabel className={cx({ className: 'fw-bold' })}>{label}</SelectInputLabel>
            )}
            <SelectWrapper style={style} className={className}>
                <SelectInputWrapper onClick={() => setOpen(!isOpen)}>
                    {!value && (
                        <span id="placeholder">{placeholder}</span>
                    )}
                    {value && (
                        <span style={{ color: 'inherit' }} className="font-bold">{value}</span>
                    )}
                    <div id="toggle-icon">
                        <Icon feather name="chevron-down" />
                    </div>
                </SelectInputWrapper>
                {isOpen && (
                    <>
                        <div id="overlay" onClick={() => setOpen(false)} className={(cx({ className: 'transparent', visibility: true }))} />
                        <OptionWrapper>
                            {optionHeader && (
                                <OptionHeader>
                                    {optionIcon && (
                                        <div id="icon">
                                            <img src={optionIcon} alt="Icon Selector" />
                                        </div>
                                    )}
                                    <InputText placeholder={headerPlaceholder} block border onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterName(event.target.value)} value={filterName} />
                                </OptionHeader>
                            )}
                            {data.length > 0 && data.map((item: any, idx: any) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <OptionItem key={idx} onClick={() => handleOptionSelect(item)}>
                                    <div id="icon">
                                        {item?.icon && (
                                            <img src={item.icon} alt="Icon" />
                                        )}
                                    </div>
                                    <div id="desc">
                                        {item.value && (
                                            <p className="m-0 font-bold font-small">
                                                {item.title}
                                                {item?.note && (
                                                    <span className="fw-normal ms-2">{item.note}</span>
                                                )}
                                            </p>
                                        )}
                                        {item?.description && (
                                            <span className="font-small">{item.description}</span>
                                        )}
                                    </div>
                                </OptionItem>
                            ))}
                        </OptionWrapper>
                    </>
                )}
            </SelectWrapper>
        </>
    );
};

SelectInput.defaultProps = {
    value: '',
    label: '',
    placeholder: '',
    style: undefined,
    className: undefined,
    optionHeader: false,
    headerPlaceholder: '',
    optionIcon: '',
    onOptionSelected: () => { }
};

export default SelectInput;
