import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Image from './Image';
import logo from '../assets/img/logo-sharp-red.png';
import Text from './Text';
import Icon from './Icon';
import menuList from '../constant/menuList';
import { useAppDispatch, useAppSelector } from '../hooks/useRedux';
import ScrollView from './ScrollView';
import { logout } from '../redux/action';

const SidebarMenuLabel = styled.div`
    position: relative;
    display: block;
`;

const SidebarWrapper = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px 20px 0 0;
    max-width: calc(100% - 80%);
    background: var(--default-primary);
    top: 0;
    left: 0;
    transition: max-width .3s ease;
    z-index: 90;

    @media only screen and (max-width: 768px) {
        left: 120%;
        max-width: 60%;
    }

    &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 100%;
        background: var(--default-canvas);
        border-radius: 50px 0 0 50px;
        right: 0;
        top: 0;

        @media only screen and (max-width: 768px) {
            content: none;
        }
    }

    .logo {
        position: relative;
        display: block;
        text-align: center;
        height: 28px;
        background: var(--default-primary);
        padding-bottom: 10px;

        img {
            filter: brightness(0) invert(1);
            height: 100%;
        }
    }

    #menu-bottom {
        padding-bottom: 30px;
    }

    &.minimize {
        padding-top: 50px;
        max-width: 100px;

        .logo,
        .menu-title {
            display: none;
        }

        ${SidebarMenuLabel} {
            height: 18px;

            .menu-label {
                display: none;
            }

            &::before {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 100px;
                background: var(--default-fg-invert);
                top: 50%;
                left: 50%;
                visibility: visible;
                transform: translate(-50%, -50%) translateX(-5px);
            }
        }

        #menu-bottom {
            padding-bottom: 0;
        }
    }
`;

const SidebarMenuList = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    padding: 0 0 10px 1rem;

    .menu-label {
        color: var(--default-fg-invert);
    }
`;

const SidebarMenuItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    gap: 10px;
    margin: 2px 0;
    border-radius: 100px 0 0 100px;
    cursor: pointer;

    p {
        display: block;
        color: var(--default-fg-invert);
        vertical-align: middle;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;

        &.active {
            font-weight: 600;
        }
    }

    &:has(> p.active) {
        background: rgba(255, 255, 255, .35);
    }

    &:hover {
        background: rgba(255, 255, 255, .2);
    }
`;

const SidebarMenuGroup = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 5px 0;

    ${SidebarMenuItem}:nth-of-type(1) {
        margin-top: 5px;
    }
`;

const SidebarBottomMenu = styled.div`
    position: sticky;
    display: block;
    background: var(--default-primary);
    bottom: 5px;
    left: 0;
    padding: 10px 0;
    border-top: 1px solid rgba(255, 255, 255, .2);
`;

interface ISidebarProps {
    activeMenu?: string
}

const Sidebar: React.FC<ISidebarProps> = (props) => {
    const { activeMenu } = props;
    const { sidebar } = useAppSelector((state) => state.uiReducer);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const linkTo = (to: string) => {
        navigate(`/admin${to}`);
    };

    // useEffect(() => {
    //     if (!sidebar) {
    //     }
    // }, [sidebar]);

    return (
        <SidebarWrapper className={!sidebar ? 'minimize' : ''}>
            <div className="logo">
                <Image src={logo} />
            </div>
            <ScrollView>
                <SidebarMenuList>
                    {menuList.map((item: any) => (
                        <SidebarMenuGroup key={item.id}>
                            <SidebarMenuLabel>
                                <Text paragraph fontSmall fontBold className="menu-label">{item.title}</Text>
                            </SidebarMenuLabel>
                            {item.menu.map((menu: any) => (
                                <SidebarMenuItem key={menu.id} onClick={() => linkTo(menu.link)}>
                                    <Icon invert name={menu.icon} />
                                    <Text paragraph className={`m-0 menu-title ${activeMenu === menu.id ? 'active' : ''}`}>{menu.title}</Text>
                                </SidebarMenuItem>
                            ))}
                        </SidebarMenuGroup>
                    ))}
                    <SidebarBottomMenu id="menu-bottom">
                        <SidebarMenuItem onClick={() => dispatch(logout())}>
                            <Icon invert name="logout" />
                            <Text paragraph className="m-0 menu-title">Logout</Text>
                        </SidebarMenuItem>
                    </SidebarBottomMenu>
                </SidebarMenuList>
            </ScrollView>
        </SidebarWrapper>
    );
};

Sidebar.defaultProps = {
    activeMenu: ''
};

export default Sidebar;
