import styled from 'styled-components';

const BadgeWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: auto;
    padding: 2px 10px;
    border-radius: 4px;
    
    &.status-error {
        background: var(--color-error);
        color: var(--color-white);
    }

    &.status-success {
        background: var(--color-success);
        color: var(--color-white);
    }

    &.status-warning {
        background: var(--color-warning);
        color: var(--color-black);
    }

    &.gray {
        background: #6c757d;
        color: var(--color-white);
    }
`;

export { BadgeWrapper };
