import React from 'react';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PaginationWrapper = styled.nav`
    width: 100%;
    height: 100%;
    display: block;
    margin-top: 10px;

    .paginate {
        position: relative;
        height: 36px;
        width: 100%;
        margin: 0;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        flex-direction: row;
        list-style-type: none;
        
        .paginate-next,
        .paginate-prev,
        .paginate-page {
            position: relative;
            display: inline-flex;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            background: transparent;
            color: var(--default-primary);
            border: 1px solid var(--default-border);
            border-radius: 100px;

            &.active {
                background: var(--default-primary);
                border: none;
                
                a {
                    color: var(--default-fg-invert);
                }
            }

            a {
                text-decoration: none;
                color: var(--default-primary);
            }
        }
    }
`;

interface ITableProps {
    columns: any[],
    data: any[],
    loading: boolean,
    totalList: number,
    paginate?: boolean,
    currentPage?: number,
    // eslint-disable-next-line no-unused-vars
    onPageChange: (event: any) => void
}

const Table: React.FC<ITableProps> = (props) => {
    const { columns, data, loading, totalList, onPageChange, paginate, currentPage } = props;

    // eslint-disable-next-line react/no-unstable-nested-components
    const TablePagination = () => {
        return (
            <PaginationWrapper>
                <ReactPaginate
                    className="paginate"
                    pageClassName="paginate-page"
                    activeClassName="active"
                    onPageChange={onPageChange}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(totalList / 10)}
                    forcePage={currentPage && currentPage !== 0 ? currentPage - 1 : 0}
                    nextLabel=">"
                    nextClassName="paginate-next"
                    previousLabel="<"
                    previousClassName="paginate-prev"
                />
            </PaginationWrapper>
        );
    };

    return (
        <DataTable
            noHeader
            columns={columns}
            data={data}
            progressPending={loading}
            pagination={paginate}
            paginationServer
            paginationTotalRows={totalList}
            paginationComponent={TablePagination}
            onChangePage={(page: number) => {
                onPageChange(page);
            }}
        />
    );
};

Table.defaultProps = {
    currentPage: 0,
    paginate: false
};

export default Table;
