import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabWrapper, TabItemWrapper } from './index.styled';

// interface ITabItemsArr {
//     [index: number]: { id: number, name: string, }
// }

interface ITabsProps {
    link?: boolean,
    tabItems?: any[],
    defaultTabActive?: string | number,
    fullWidth?: boolean,
    // eslint-disable-next-line no-unused-vars
    onTabChange?: (tab: { tab_active: any, data: any }) => void | any
}

const Tabs: React.FC<ITabsProps> = (props) => {
    const { link, tabItems, defaultTabActive, fullWidth, onTabChange } = props;
    const navigate = useNavigate();
    const [tabActive, setTabActive] = useState(defaultTabActive || '');

    const onClickTab = (tab: any) => {
        setTabActive(tab.key);
        const data = {
            tab_active: tab.key,
            data: tab
        };
        if (onTabChange) {
            onTabChange(data);
        }
    };

    const onClickLink = (tab: any) => {
        navigate(tab.link, { replace: false });
        setTabActive(tab.key);
    };

    useEffect(() => {
        if (defaultTabActive) {
            setTabActive(defaultTabActive);
        }
    }, []);

    return (
        <TabWrapper fullWidth={fullWidth}>
            {tabItems && tabItems?.map((tab) => (
                <TabItemWrapper key={tab.key} active={tabActive === tab.key} onClick={() => (link ? onClickLink(tab) : onClickTab(tab))}>
                    {tab.title}
                </TabItemWrapper>
            ))}
        </TabWrapper>
    );
};

Tabs.defaultProps = {
    link: false,
    tabItems: [],
    defaultTabActive: '',
    fullWidth: false,
    onTabChange: () => { }
};

export default Tabs;
