import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import Modal from '../../../components/Modal';
import SelectInput from '../../../components/SelectInput';
import API from '../../../configs/api';
import { useAppDispatch } from '../../../hooks/useRedux';
import { hideLoading, showLoading } from '../../../redux/action';

interface IRewardDialogProps {
    data?: any,
    edit?: boolean
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const RewardDialog: React.FC<IRewardDialogProps> = (props) => {
    const { data, edit, dialogHandler } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const [formVoucherEwallet, setVoucherEwallet] = useState({

    // });
    const [amount, setAmount] = useState(data.amount || '0');
    const [description, setDescription] = useState(data.description || '');
    const [reward, setReward] = useState<any>({});
    const [rewardOpt, setRewardOpt] = useState<any[]>([]);
    const [listData, setListData] = useState<any[]>([]);

    useEffect(() => {
        const opt = [];
        if (listData.length < 1) return;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < listData.length; i++) {
            const obj = {
                title: listData[i]?.name,
                value: listData[i]?.id
            };
            opt.push(obj);
        }
        setRewardOpt(opt);
    }, [listData]);

    const onSubmit = () => {
        dispatch(showLoading());
        const payload = {
            params: edit ? `/${data.id}` : '',
            body: {
                type: edit ? undefined : reward.value,
                amount,
                description
            }
        };
        if (edit) {
            API.updateReward(payload).then(() => {
                navigate(0);
            });
            return;
        }
        API.createReward(payload).then(() => {
            navigate(0);
        });
    };

    useEffect(() => {
        if (edit) return;

        dispatch(showLoading());
        const payload = {
            params: '/type'
        };
        API.getReward(payload).then((result: any) => {
            dispatch(hideLoading());
            setListData(result.data.list);
        });
    }, []);

    return (
        <Modal title={edit ? 'Edit Reward' : 'Add Reward'} visibility modalHandler={dialogHandler}>
            {!edit && rewardOpt.length > 0 && (
                <SelectInput
                    label="Type"
                    options={rewardOpt}
                    value={reward.title}
                    onOptionSelected={(value: any) => {
                        setReward(value);
                    }}
                />
            )}
            <InputText
                label="Amount"
                type="text"
                block
                border
                value={amount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
            />
            <InputText
                label="Description"
                type="text"
                block
                border
                value={description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            />
            <div className="row mt-4">
                <div className="col-6">
                    <Button borderPrimary block fullWidth onClick={() => dialogHandler(false)}>Cancel</Button>
                </div>
                <div className="col-6">
                    <Button primary block fullWidth onClick={() => onSubmit()}>{edit ? 'Edit' : 'Add'}</Button>
                </div>
            </div>
        </Modal>
    );
};

RewardDialog.defaultProps = {
    data: {},
    edit: false
};

export default RewardDialog;
