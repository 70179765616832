import React, { useState, useEffect } from 'react';
import Badge from '../../../components/Badge';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import useDate from '../../../helpers/useDate';
import InputText from '../../../components/InputText';
import Card from '../../../components/Card';
import useStatusTransaction from '../../../hooks/useStatusTransaction';

const TransactionCheck: React.FC = () => {
    const [phone, setPhone] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { list, totalList, loading } = useStatusTransaction({ currentPage });
    const [totalRow, setTotalRow] = useState(0);

    useEffect(() => {
        if (totalList > 0) {
            setTotalRow(totalList);
        }
    }, [list]);

    useEffect(() => {
        setCurrentPage(1);
    }, []);

    const columns: any[] = [
        {
            name: '#',
            sortable: true,
            width: '70px',
            selector: (row: any) => row.id
        },
        {
            name: 'Created At',
            sortable: true,
            width: '120px',
            selector: (row: any) => useDate.friendlyDate(row.created_at)
        },
        {
            name: 'Kode Produk',
            sortable: true,
            selector: (row: any) => row.kodeproduk
        },
        {
            name: 'No. Tujuan',
            sortable: true,
            selector: (row: any) => row.notujuan
        },
        {
            name: 'Id Transaksi',
            sortable: true,
            selector: (row: any) => row.idtrx
        },
        {
            name: 'Status',
            sortable: true,
            selector: (row: any) => (
                <>
                    {row.status === 'Proses' && (
                        <Badge status="warning">{row.status}</Badge>
                    )}
                    {row.status === 'Sukses' && (
                        <Badge status="success">{row.status}</Badge>
                    )}
                    {row.status === 'Gagal' && (
                        <Badge status="error">{row.status}</Badge>
                    )}
                </>
            )
        }
    ];

    return (
        <>
            <div className="row align-items-center my-3">
                <div className="col-6" />
                <div className="col-4">
                    <InputText
                        border
                        placeholder="Search"
                        value={phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                        iconRight="search"
                    // iconRightOnClick={() => onSearchSubmit()}
                    />
                </div>
                <div className="col-2">
                    <Button block primary fullWidth>Export</Button>
                </div>
            </div>
            <Card border>
                <Table
                    data={list}
                    columns={columns}
                    loading={loading}
                    paginate
                    currentPage={currentPage}
                    totalList={totalRow}
                    onPageChange={(e) => {
                        setCurrentPage(e.selected + 1);
                    }}
                />
            </Card>
        </>
    );
};

export default TransactionCheck;
