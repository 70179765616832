import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from '../../utils/cx';
import { ButtonWrapper } from './index.styled';

interface IButtonProps {
    children: React.ReactNode | string,
    onClick?: any,
    shadow?: boolean,
    block?: boolean,
    fullWidth?: boolean,
    link?: boolean,
    linkReplace?: boolean,
    to?: string,
    primary?: boolean,
    secondary?: boolean,
    gradient?: boolean,
    border?: boolean,
    borderPrimary?: boolean,
    rounded?: boolean,
    disabled?: boolean,
    className?: string,
    style?: React.CSSProperties
}

const Button: React.FC<IButtonProps> = (props) => {
    const { children, onClick, shadow, block, fullWidth, link, linkReplace, to, primary, secondary, gradient, border, borderPrimary, rounded, className, style, disabled } = props;
    const navigate = useNavigate();

    const onClickLink = () => {
        navigate(to || '', { replace: linkReplace });
    };

    return (
        <ButtonWrapper disabled={disabled} shadow={shadow} className={cx({ className, block, fullWidth, primary, secondary, gradient, border, borderPrimary, rounded })} style={style} onClick={link ? onClickLink : onClick}>
            {children}
        </ButtonWrapper>
    );
};

Button.defaultProps = {
    onClick: () => { },
    shadow: false,
    block: false,
    fullWidth: false,
    link: false,
    linkReplace: false,
    to: '/',
    primary: false,
    secondary: false,
    gradient: false,
    border: false,
    borderPrimary: false,
    rounded: false,
    disabled: false,
    className: '',
    style: undefined
};

export default Button;
